import {SET_DEPOSIT_INFO} from './types';

const state = {
  depositInfo: {}
};

const mutations = {
  [SET_DEPOSIT_INFO](state, info) {
    state.depositInfo = info;
  }
};

import * as getters from "./getters";
import * as actions from "./actions";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};