<template>
  <div>
    <a-result
      v-if="!purchased"
      class="hide-ant-result-icon"
      style="padding: 0"
    >
      <template #extra>
        <div>
          <a-alert v-if="discountErr.visible" style="margin-bottom: 10px;" type="error" :message="discountErr.message" banner closable @close="closeAlert"/>
          <a-input-search
            style="margin-bottom: 30px; width: 60%;"
            key="discount"
            placeholder="Enter discount code (if any)"
            enter-button="Apply"
            @search="applyPromotion"
          />
        </div>
        <a-button key="purchase" type="primary" :loading="loading.purchase" @click="handlePurchase">
          <a-icon type="dollar" /> Purchase
        </a-button>
        <a-button key="cancel" @click="closeModal">
          <a-icon type="close-circle" />Cancel
        </a-button>
      </template>
      <a-descriptions
        bordered
        layout="vertical"
        size="small"
      >
        <span slot="title">Billing infomation ({{threads ? 'Pay per thread' : 'Pay per use'}})</span>
        <a-descriptions-item label="Type" v-if="type == 'funcaptcha'">
          <a-avatar src="/images/funcaptcha.png" style="margin-right: 5px"></a-avatar>Funcaptcha
        </a-descriptions-item>
        <a-descriptions-item label="Type" v-if="type == 'image'">
          <a-avatar src="/images/itt.png" style="margin-right: 5px"></a-avatar>Image To Text
        </a-descriptions-item>
        <a-descriptions-item label="Period">
          {{period}} days
        </a-descriptions-item>
        <a-descriptions-item v-if="quantity" label="Quantity">
          <b>{{ numberFormater.format(quantity) }}</b>
        </a-descriptions-item>
        <a-descriptions-item v-if="threads" label="Threads">
          <b>{{ threads }}</b>
        </a-descriptions-item>
        <a-descriptions-item v-if="quantity" label="Price/1000">
          <b>${{ price }}</b>
        </a-descriptions-item>
        <a-descriptions-item v-if="threads" label="Price">
          <b>${{ price }}</b>/Thread
        </a-descriptions-item>
        <a-descriptions-item label="Costs">
          <div v-if="discount.code">
            <div>
              <span class="original-price">${{ parseFloat(amount.toFixed(5)) }}</span>
              <span class="discount">-{{ discount.discount_value }} {{ (discount.discount_type == 'fixed') ? '$' : '%' }}</span>
            </div>
            <div class="new-price">
              ${{ parseFloat(costs.toFixed(5)) }}
            </div>
          </div>
          <div v-else>
            <b>${{ parseFloat(amount.toFixed(5)) }}</b>
          </div>
        </a-descriptions-item>
      </a-descriptions>
    </a-result>

    <a-result
      v-if="purchased"
      status="success"
      title="Successfully Purchased!"
      sub-title="Package purchased successfully. You can access the Member Area -> Packages to get the API-Key and start using our service. Thank you!"
    >
      <template #extra>
        <a-button v-if="invoiceType == 'register'" key="memberarea" type="primary">
          <router-link :to="{name: 'MemberPackagePage'}">See Packages</router-link>
        </a-button>
        <a-button v-if="invoiceType == 'register'" key="cancel" @click="closeModal">
          Buy Again
        </a-button>
        <a-button v-if="invoiceType == 'renew'" key="cancel" @click="closeModal">
          <a-icon type="close-circle" />Cancel
        </a-button>
      </template>
    </a-result>
  </div>
</template>

<style scoped>
.original-price {
  text-decoration: line-through;
  color: #ff4d4f;
}
.discount {
  color: #ff4d4f;
  margin-left: 8px;
}
.new-price {
  font-size: 1.2em;
  color: #52c41a;
}
</style>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    invoiceType: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    period: {
      type: Number,
      required: true
    },
    quantity: {
      type: Number,
      required: false
    },
    threads: {
      type: Number,
      required: false
    },
    price: {
      type: Number,
      required: true
    },
    amount: {
      type: Number,
      required: true
    },
    package_id: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      loading: {
        purchase: false
      },
      purchased: false,
      numberFormater: new Intl.NumberFormat('en-US'),
      costs: undefined,
      discount: {
        code: undefined,
        discount_type: undefined,
        discount_value: undefined
      },
      discountErr: {
        visible: false,
        message: undefined
      }
    }
  },
  
  computed: {
    unlimited() {
      return (this.threads && this.threads > 0) ? true : false;
    }
  },

  methods: {
    ...mapActions('deposit', {
      createRegisterTransaction: "createRegisterTransaction",
      createRenewTransaction: "createRenewTransaction"
    }),

    ...mapActions('promotion', {
      appLyDiscount: "appLyDiscount"
    }),

    closeModal() {
      this.$emit('close');
    },

    closeAlert() {
      this.discountErr.visible = false;
    },

    async applyPromotion(value) {
      const discountCode = String(value).trim();

      try {
        const discountInfo = await this.appLyDiscount({
          type: this.type,
          period: this.period,
          quantity: this.unlimited ? this.threads : this.quantity,
          unlimited: this.unlimited,
          code: discountCode
        });        
        this.discount = discountInfo;

        var costs = this.amount;
        if(this.discount.discount_type === 'fixed') {
          costs -= this.discount.discount_value;
        }
        else {
          costs -= (costs * this.discount.discount_value) / 100;
        }

        if(costs < 0) {
          costs = 0;
        }

        this.costs = costs;
        this.discountErr.message = undefined;
        this.closeAlert();
      } 
      catch (error) {
        this.discountErr.message = error?.response?.data?.error?.message || error?.response?.data || error.message;
        this.discountErr.visible = true;
      }

    },

    async handlePurchase() {
      this.loading.purchase = true;
      try {
        if (this.invoiceType == 'register') {
          await this.createRegisterTransaction({
            type: this.type,
            period: this.period,
            quantity: this.unlimited ? this.threads : this.quantity,
            unlimited: this.unlimited,
            code: this.discount.code
          });
          this.$emit('purchased');
          this.purchased = true;
        } else if (this.invoiceType == 'renew') {
          await this.createRenewTransaction({
            package_id: this.package_id,
            code: this.discount.code
          });
          this.$emit('purchased');
          this.purchased = true;
        }
      }
      catch(error) {
        const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

        if (message.includes('Unauthorized')) {
          this.$router.push({name: 'LoginPage'});
        }

        if (message.includes('Your balance is not enough')) {
          const notiKey = 'warning-deposit' + Date.now();
          this.$notification.warning({
            message: message,
            description: 'Your account balance is not enough. Please go to the Deposit page to add more funds.',
            btn: h => {
              return h(
                'a-button',
                {
                  props: {
                    type: 'primary',
                    icon: 'forward'
                  },
                  on: {
                    click: () => {
                      this.$router.push({name: 'MemberDepositPage'});
                      this.$notification.close(notiKey);
                    },
                  },
                },
                'Goto Deposit',
              );
            },
            key: notiKey
          });
        } else {
          this.$notification.error({message});
        }
      }
      this.loading.purchase = false;
    },
  }
}
</script>