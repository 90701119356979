import Vue from "vue";
import Vuex from "vuex";

import setting from "@/stores/setting";
import auth from "@/stores/auth";
import register from "@/stores/register";
import recovery from "@/stores/recovery";
import account from "@/stores/account";
import deposit from "@/stores/deposit";
import prices from "@/stores/prices";
import transaction from "@/stores/transaction";
import packages from "@/stores/package";
import promotion from "@/stores/promotion"
import statistic from "@/stores/statistic";
import tracking from "@/stores/tracking";

Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		setting,
		auth,
		register,
		recovery,
		account,
		deposit,
		prices,
		transaction,
		packages,
		promotion,
		statistic,
		tracking
	}
});

export default store;