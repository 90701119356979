import request from "./base";
const path = "/api/packages";
const adminPath = "/api/admin/packages";

export default {
  getUserPackages: function () {
    return request().get(path + "/");
  },
  changeAPIKey: function(params) {
    return request().post(path + '/change-api-key', params);
  },
  deletePackage: function(params) {
    return request().delete(path + '/', {data: params});
  },
  getPackages: function (params) {
    return request().get(adminPath + "/", {params});
  },
  changeAPIKeyByAdmin: function(params) {
    return request().post(adminPath + '/change-api-key', params);
  },
  deletePackagesByAdmin: function(params) {
    return request().delete(adminPath + '/', {data: params});
  },
  updatePackagePeriods: function(params) {
    return request().put(adminPath + '/update-period', params);
  }
};
