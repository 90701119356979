import request from './base';
const path = '/api/discounts';
const adminPath = '/api/admin/discounts';

export default {
  applyDiscountCode: function(params) {
    return request().post(path + '/apply', params);
  },

  getDisountCodes: function(params) {
    return request().get(adminPath + '/', {params});
  },

  createDiscountCode: function(params) {
    return request().post(adminPath + '/', params);
  },

  updateDiscountCode: function(id, params) {
    return request().put(adminPath + '/' + id, params);
  },

  deleteDiscountCodes: function(params) {
    return request().delete(adminPath + '/', {data: params});
  }
}