import * as getters from "./getters";
import * as actions from "./actions";
import { SET_TRACKINGS } from "./types";

const state = {
  trackings: [],
  analytic: {
    error: 0,
    pending: 0,
    processing: 0,
    success: 0
  }
};

const mutations = {
  [SET_TRACKINGS](state, trackings) {
    trackings.sort((a, b) => b.createdTime - a.createdTime);

    state.trackings = trackings;
    let error=0,pending=0,processing=0,success=0;

    for (let i=0; i<trackings.length; i++) {
      if (trackings[i].status == 'PENDING') {
        pending++;
      }

      if (trackings[i].status == 'PROCESSING') {
        processing++;
      }

      if (trackings[i].status == 'ERROR') {
        error++;
      }

      if (trackings[i].status == 'SUCCESS') {
        success++;
      }
    }

    state.analytic.error = error;
    state.analytic.pending = pending;
    state.analytic.processing = processing;
    state.analytic.success = success;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};