/* eslint-disable no-unused-vars */
import PackageService from '@/services/package';
import { SET_PACKAGES, SET_PACKAGE, DEL_PACKAGE, SET_PAGINATION } from './types';

export async function getUserPackages({commit}) {
  const {data} = await PackageService.getUserPackages();
  commit(SET_PACKAGES, data.data.packages);
  return data.data;
}

export async function changeAPIKey({commit}, params) {
  const {data} = await PackageService.changeAPIKey(params);
  commit(SET_PACKAGE, data.data.package);
  return data.data;
}

export async function deletePackage({commit}, params) {
  const {data} = await PackageService.deletePackage(params);
  commit(DEL_PACKAGE, params.package_id);
  return data.data;
}

export async function getPackages({commit}, params) {
  const {data} = await PackageService.getPackages(params);
  commit(SET_PACKAGES, data.data.packages);
  commit(SET_PAGINATION, data.data.pagination);
  return data.data;
}

export async function deletePackagesByAdmin({commit}, params) {
  const {data} = await PackageService.deletePackagesByAdmin(params);

  for (let i=0; i<params.package_ids.length; i++) {
    commit(DEL_PACKAGE, params.package_ids[i]);
  }

  return data.data;
}

export async function changeAPIKeyByAdmin({commit}, params) {
  const {data} = await PackageService.changeAPIKeyByAdmin(params);

  for (let i=0; i<data.data.packages.length; i++) {
    commit(SET_PACKAGE, data.data.packages[i]);
  }
  
  return data.data;
}

export async function updatePackagePeriods({commit}, params) {
  const {data} = await PackageService.updatePackagePeriods(params);

  for (let i=0; i<data.data.packages.length; i++) {
    commit(SET_PACKAGE, data.data.packages[i]);
  }
  
  return data.data;
}