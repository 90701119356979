export function getTransactionsStatistic(state) {
  return state.transactions;
}

export function getAccountsStatistic(state) {
  return state.accounts;
}

export function getCaptchaSolvedStatistic(state) {
  return state.captchaSolved;
}