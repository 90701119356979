<template>
  <a-tabs default-active-key="crypto">
    <a-tab-pane key="crypto">
      <span slot="tab">
        <font-awesome-icon :icon="['fas', 'coins']" />
        Crypto
      </span>
      <crypto-deposit></crypto-deposit>
    </a-tab-pane>
    <a-tab-pane key="bank">
      <span slot="tab">
        <a-icon type="bank" />
        Bank
      </span>
      <bank-deposit></bank-deposit>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import BankDeposit from './deposit/BankDeposit.vue';
import CryptoDeposit from './deposit/CryptoDeposit.vue';

export default {
  components: {
    BankDeposit,
    CryptoDeposit
  }
}
</script>
