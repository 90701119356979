import {REMOVE_SESSION, SET_SESSION} from './types';

import AuthService from '@/services/auth';

export async function removeSession({commit}) {
  commit(REMOVE_SESSION);
}

export async function login({commit}, params) {
  const {data} = await AuthService.login(params);
  const token = data.data.token;
  commit(SET_SESSION, token);
  return data;
}

export async function getProfile() {
  const {data} = await AuthService.profile();
  return data;
}