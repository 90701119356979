import { SET_SESSION, REMOVE_SESSION } from "./types";

const SERVICE_ACCESS_TOKEN_KEY = 'access_token';
const SESSION_EMAIL = 'email';
const SESSION_ROLE = 'role';

function decodeJwt(jwt) {
  const payloadBase64Url = jwt.split('.')[1];
  const payloadBase64 = payloadBase64Url.replace(/-/g, '+').replace(/_/g, '/');
  const payloadJson = atob(payloadBase64);
  const payload = JSON.parse(payloadJson);
  return payload;
}

const state = {
  accessToken: localStorage.getItem(SERVICE_ACCESS_TOKEN_KEY) || undefined,
  email: localStorage.getItem(SESSION_EMAIL) || undefined,
  role: localStorage.getItem(SESSION_ROLE) || undefined,
};

const mutations = {
  [SET_SESSION](state, accessToken) {
    let email = null;
    let role = null;
    try {
      const payload = decodeJwt(accessToken);
      email = payload.email;
      role = payload.role;
    }
    catch(error) {
      email = null;
      role = null;
    }

    state.accessToken = accessToken;
    state.email = email;
    state.role = role;

    localStorage.setItem(SERVICE_ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(SESSION_EMAIL, email);
    localStorage.setItem(SESSION_ROLE, role);
  },
  [REMOVE_SESSION](state) {
    state.accessToken = undefined;
    state.email = undefined;
    state.role = undefined;
    localStorage.removeItem(SERVICE_ACCESS_TOKEN_KEY);
    localStorage.removeItem(SESSION_EMAIL);
    localStorage.removeItem(SESSION_ROLE);
  }
};

import * as getters from "./getters";
import * as actions from "./actions";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};