import Vue from 'vue';
import * as getters from "./getters";
import * as actions from "./actions";
import { SET_DISCOUNTS, SET_PAGINATION, CREATE_DISCOUNT, UPDATE_DISCOUNT, DEL_DISCOUNT } from "./types";

const state = {
  discounts: [],
  pagination: undefined
};

const mutations = {
  [SET_DISCOUNTS](state, discounts) {
    state.discounts = discounts;
  },
  [SET_PAGINATION](state, pagination) {
    state.pagination = pagination;
  },
  [CREATE_DISCOUNT](state, discount) {
    state.discounts.unshift(discount)
  },
  [UPDATE_DISCOUNT](state, discount) {
    const updateDiscountNeededIndex = state.discounts.findIndex(d => d._id == discount._id);

    if (updateDiscountNeededIndex > -1) {
      Vue.set(state.discounts, updateDiscountNeededIndex, discount);
    }
  },
  [DEL_DISCOUNT](state, codeDiscount) {
    const deleteDiscountNeededIndex = state.discounts.findIndex(d => d._id == codeDiscount);

    if (deleteDiscountNeededIndex > -1) {
      state.discounts.splice(deleteDiscountNeededIndex, 1);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};