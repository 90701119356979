import Vue from 'vue';
import * as getters from "./getters";
import * as actions from "./actions";
import { SET_PACKAGES, SET_PAGINATION, SET_PACKAGE, DEL_PACKAGE } from "./types";

const state = {
  packages: [],
  pagination: undefined
};

const mutations = {
  [SET_PACKAGES](state, packages) {
    state.packages = packages;
  },
  [SET_PAGINATION](state, pagination) {
    state.pagination = pagination;
  },
  [SET_PACKAGE](state, pkg) {
    const updatePkgNeededIndex = state.packages.findIndex(p => p._id == pkg._id);

    if (updatePkgNeededIndex > -1) {
      Vue.set(state.packages, updatePkgNeededIndex, pkg);
    }
  },
  [DEL_PACKAGE](state, packageId) {
    const deletePkgNeededIndex = state.packages.findIndex(p => p._id == packageId);

    if (deletePkgNeededIndex > -1) {
      state.packages.splice(deletePkgNeededIndex, 1);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};