/* eslint-disable no-unused-vars */
import TransactionService from '@/services/transaction';
import { SET_TRANSACTIONS, SET_PAGINATION } from './types';

export async function getTransactions({commit}, params) {
  const {data} = await TransactionService.getTransactions(params);
  commit(SET_TRANSACTIONS, data.data.transactions);
  return data.data;
}

export async function getTransactionsFromAdmin({commit}, params) {
  const {data} = await TransactionService.getTransactionsFromAdmin(params);
  commit(SET_TRANSACTIONS, data.data.transactions);
  commit(SET_PAGINATION, data.data.pagination);
  return data.data;
}

export async function createTransactions({commit}, params) {
  const {data} = await TransactionService.createTransactions(params);
  return data.data;
}