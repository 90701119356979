import { render, staticRenderFns } from "./PrivacyPolicyPage.vue?vue&type=template&id=30e2fec7&"
var script = {}
import style0 from "./PrivacyPolicyPage.vue?vue&type=style&index=0&id=30e2fec7&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports