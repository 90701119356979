<template>
  <div style="padding: 24px">
    <a-row id="hero" type="flex" justify="center" align="middle" class="hero-section">
      <a-col span="12" class="hero-content">
        <h1>Welcome to MacroRC Captcha</h1>
        <p>
          Our service is fast, stable, and cheap. We provide the best captcha solving solutions with high success rates and affordable prices.
        </p>
        <div>
          <span style="margin-right: 15px;"><a-icon style="color: #52c41a;" type="check-circle" /> Speed</span>
          <span style="margin-right: 15px"><a-icon style="color: #52c41a;" type="check-circle" /> Stability</span>
          <span><a-icon style="color: #52c41a;" type="check-circle" /> Affordability</span>
        </div>
        <br>
        <a-button type="primary" style="margin-right: 10px">
          <router-link :to="{ name: isLoggedIn ? 'MemberProfilePage' : 'RegisterPage' }">
            <a-icon type="login" /> Get Started
          </router-link>
        </a-button>
        <a-button>
          <router-link :to="{ name: 'FreeTrialPage' }">
            <a-icon type="thunderbolt" /> Free trial
          </router-link>
        </a-button>
      </a-col>
    </a-row>

    <a-row id="pricing" type="flex" justify="center" align="middle">
      <a-col span="12" class="section">
        <h1>Supported Captcha Types</h1>
        <captcha-type></captcha-type>
      </a-col>
    </a-row>

    <a-row id="pricing-caculator" type="flex" justify="center" align="middle">
      <a-col span="12" class="section">
        <h1>Estimate your costs</h1>
        <price-caculator></price-caculator>
      </a-col>
    </a-row>

    <a-row id="product" type="flex" justify="center" align="middle">
      <a-col span="12" class="section section-end">
        <h1>Browser Extension</h1>
        <extension-support></extension-support>
      </a-col>
    </a-row>
  </div>
</template>

<style scoped>
.hero-section {
  background-color: #f0f2f5;
  padding: 50px 0;
}

.hero-content {
  text-align: center;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .hero-content {
    text-align: center;
    width: 100%;
    padding: 0 5px;
  }

  .section {
    text-align: center;
    width: 100%;
  }
}

.section {
  text-align: center;
  margin: 50px 0 0 0;
}

.section-end {
  margin-bottom: 50px;
}
</style>

<script>
import { mapGetters } from 'vuex';
import PriceCaculator from './PriceCaculator.vue';
import CaptchaType from './CaptchaType.vue';
import ExtensionSupport from './ExtensionSupport.vue';

export default {
  components: {
    PriceCaculator,
    CaptchaType,
    ExtensionSupport
  },
  computed: {
    ...mapGetters("auth", {
      isLoggedIn: "isLoggedIn"
    })
  },
};
</script>
