import axios from "axios";
import authStore from "@/stores/auth";
import config from "@/config/index.config";

export default () => {
  const serviceUri = config.serviceUri;
  const accessToken = authStore.state.accessToken;
  const headers = {};

  if (accessToken) {
    headers.Authorization = 'Bearer ' + accessToken;
  }
  
  return axios.create({
    baseURL: serviceUri,
    headers: headers
  });
};
