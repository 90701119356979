var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-button',{staticClass:"crypto-btn",staticStyle:{"background-color":"#181A20"},on:{"click":function($event){_vm.depositType = _vm.DepositTypes.BINANCE}}},[_c('img',{attrs:{"src":"/images/binance.svg"}})]),_c('br'),_c('br'),(_vm.depositType == _vm.DepositTypes.BINANCE)?_c('a-form',{staticClass:"submit-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[(_vm.binanceDepositAmountUsd)?_c('a-form-item',[_c('a-alert',{attrs:{"message":`You will receive ~ $${_vm.binanceDepositAmountUsd}`,"type":"info","show-icon":""}})],1):_vm._e(),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'amount',
          { rules: [
            { required: true, message: 'Please enter amount!' },
            { pattern: /^\d+([,.]\d+)?$/, message: 'Amount must be a number' }
          ] },
        ]),expression:"[\n          'amount',\n          { rules: [\n            { required: true, message: 'Please enter amount!' },\n            { pattern: /^\\d+([,.]\\d+)?$/, message: 'Amount must be a number' }\n          ] },\n        ]"}],attrs:{"type":"text","placeholder":"Amount","addon-before":`[Binance]`},on:{"change":_vm.caculateAmount}},[_c('a-select',{staticStyle:{"width":"80px"},attrs:{"slot":"addonAfter","default-value":_vm.binanceCurrency},slot:"addonAfter",model:{value:(_vm.binanceCurrency),callback:function ($$v) {_vm.binanceCurrency=$$v},expression:"binanceCurrency"}},[_c('a-select-option',{attrs:{"value":"USDT"}},[_vm._v(" USDT ")]),_c('a-select-option',{attrs:{"value":"BTC"}},[_vm._v(" BTC ")]),_c('a-select-option',{attrs:{"value":"ETH"}},[_vm._v(" ETH ")]),_c('a-select-option',{attrs:{"value":"BNB"}},[_vm._v(" BNB ")]),_c('a-select-option',{attrs:{"value":"SOL"}},[_vm._v(" SOL ")]),_c('a-select-option',{attrs:{"value":"USDC"}},[_vm._v(" USDC ")])],1)],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'username',
          { rules: [
            { required: true, message: 'Please enter your Binance username!' }
          ] },
        ]),expression:"[\n          'username',\n          { rules: [\n            { required: true, message: 'Please enter your Binance username!' }\n          ] },\n        ]"}],attrs:{"type":"text","placeholder":"Username, ex: User-123456","addon-before":`[Binance]`}},[_c('a-tooltip',{attrs:{"slot":"suffix"},slot:"suffix"},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.45)"},attrs:{"type":"info-circle"}}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Input your Binance username, see the guide to obtain your Binance username "),_c('a',{attrs:{"href":"https://docx.mrccaptcha.com/instructions-for-getting-binance-username","target":"_blank"}},[_vm._v("here")])])],1)],1)],1),_c('a-form-item',[_c('a-alert',{attrs:{"message":"Please note, enter the correct amount, currency, and your Binance account username. Otherwise, the transaction will not be processed!","type":"warning","show-icon":""}})],1),_c('google-recaptcha-v2',{ref:"grecaptcha-component"}),_c('a-form-item',[_c('a-button',{staticClass:"submit-form-button",attrs:{"loading":_vm.loading.createBinanceTransaction,"type":"primary","html-type":"submit"}},[_c('a-icon',{attrs:{"type":"qrcode"}}),_vm._v(" Create transaction ")],1)],1),(_vm.currentBinanceTransaction)?_c('a-modal',{attrs:{"destroyOnClose":true,"title":"Scan QR with your Binance app"},on:{"ok":_vm.onCloseBinancePayInvoiceModal,"cancel":_vm.onCloseBinancePayInvoiceModal},model:{value:(_vm.binancePayInvoiceVisible),callback:function ($$v) {_vm.binancePayInvoiceVisible=$$v},expression:"binancePayInvoiceVisible"}},[_c('binance-qr',{key:_vm.keys.binancePayInvoice,ref:"binance-pay-invoice",attrs:{"createdAt":_vm.currentBinanceTransaction.createdAt,"expiredAt":_vm.currentBinanceTransaction.expiredAt,"usd-amount":_vm.currentBinanceTransaction.amount,"id":_vm.currentBinanceDepositInfo.id,"amount":_vm.currentBinanceDepositInfo.amount,"qr":_vm.currentBinanceDepositInfo.qr,"currency":_vm.binanceCurrency}})],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }