import { render, staticRenderFns } from "./GoogleRecaptchaV2.vue?vue&type=template&id=42b3f778&"
import script from "./GoogleRecaptchaV2.vue?vue&type=script&lang=js&"
export * from "./GoogleRecaptchaV2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports