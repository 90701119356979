<template>
  <a-card title="Free trial" class="root-card">
    <a-tabs default-active-key="funcaptcha">
      <a-tab-pane key="funcaptcha">
        <span slot="tab"><a-avatar src="/images/funcaptcha.png" style="margin-right: 5px"></a-avatar>Funcaptcha</span>
        <a-row :gutter="[24,8]">
          <a-col :span="isMobile ? 24 : 12">
            <a-input v-model="funcaptcha.sitekey" placeholder="Input Funcaptcha site key" style="width: 100%"></a-input>
            <google-recaptcha-v2 ref="grecaptcha-component-2" style="margin-bottom: 10px"></google-recaptcha-v2>
            <a-button type="primary" @click="solveFuncaptcha" :loading="loading.solveFuncaptcha"><a-icon type="rocket" /> {{loadingText.solveFuncaptcha}}</a-button>
          </a-col>
          <a-col :span="isMobile ? 24 : 12">
            <a-textarea v-model="funcaptcha.result" placeholder="Result will be displayed here" :rows="7" :autoSize="{minRows: 7, maxRows: 7}" readonly/>
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane key="itt">
        <span slot="tab"><a-avatar src="/images/itt.png" style="margin-right: 5px"></a-avatar>Image To Text</span>
        <a-row :gutter="[24,8]">
          <a-col :span="isMobile ? 24 : 12">
            <a-upload
              class="upload-wd-100"
              accept="image/*"
              list-type="picture-card"
              :file-list="imageToText.fileList"
              :beforeUpload="beforeUploadImage"
              :remove="onRemoveImage"
              @preview="imageToText.preview = true"
              @change="handleChangeImageList"
            >
              <div v-if="imageToText.fileList.length == 0">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  Upload
                </div>
              </div>
            </a-upload>
            <a-modal v-model="imageToText.preview" :footer="null" @cancel="imageToText.preview = false">
              <img alt="example" style="width: 100%" :src="imageToText.image" />
            </a-modal>
            <google-recaptcha-v2 ref="grecaptcha-component" style="margin-bottom: 10px"></google-recaptcha-v2>
            <a-button type="primary" @click="solveImage" :loading="loading.solveImage"><a-icon type="rocket" /> {{loadingText.solveImage}}</a-button>
          </a-col>
          <a-col :span="isMobile ? 24 : 12">
            <a-textarea v-model="imageToText.result" placeholder="Result will be displayed here" :rows="7" :autoSize="{minRows: 7, maxRows: 7}" readonly/>
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs>
  </a-card>
</template>

<style>
.upload-wd-100 .ant-upload-select-picture-card {
  width: 100%;
  height: 156.6px;
}

.upload-wd-100 .ant-upload-list-item {
  width: 100%;
  height: 156.6px;
}

.upload-wd-100 .ant-upload-list-picture-card-container {
  width: 100%;
  height: 156.6px;
}
</style>

<script>
import GoogleRecaptchaV2 from '../utils/GoogleRecaptchaV2.vue';
import TrialService from '@/services/trial';
import {wait} from '@/utils/common';
import {mapGetters} from 'vuex';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  components: {
    GoogleRecaptchaV2
  },
  data() {
    return {
      imageToText: {
        preview: false,
        image: '',
        fileList: [],
        result: undefined
      },
      funcaptcha: {
        sitekey: undefined,
        result: undefined
      },
      loading: {
        solveImage: false,
        solveFuncaptcha: false
      },
      loadingText: {
        solveImage: 'Solve Captcha',
        solveFuncaptcha: 'Solve Captcha'
      }
    }
  },
  computed: {
    ...mapGetters("auth", {
      role: "getRole"
    }),

    isAdmin() {
      return this.role == 'admin';
    },

    isMobile() {
      return innerWidth <= 768;
    },

    grecaptchaComponent() {
      return this.$refs['grecaptcha-component'];
    },

    grecaptchaComponent2() {
      return this.$refs['grecaptcha-component-2'];
    }
  },
  destroyed() {
    clearInterval(this.loopGetTaskResult);
  },
  methods: {
    handleChangeImageList({ fileList }) {
      this.fileList = fileList;
    },
    beforeUploadImage(file) {
      getBase64(file).then(image => {
        this.imageToText.image = image;
        this.imageToText.fileList.push({
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: image,
        });
      });
      return false;
    },
    onRemoveImage() {
      this.imageToText.image = '';
      this.imageToText.fileList = [];
    },

    async whileTaskResult(taskId, interval = 3000) {
      const {data} = await TrialService.getTaskResult(taskId);

      if (['SUCCESS', 'ERROR'].includes(data.Status)) {
        return data;
      }

      return new Promise((resolve, reject) => {
        this.loopGetTaskResult = setInterval(async () => {
          try {
            const {data} = await TrialService.getTaskResult(taskId);

            if (['SUCCESS', 'ERROR'].includes(data.Status)) {
              clearInterval(this.loopGetTaskResult);
              return resolve(data);
            }
          }
          catch(error) {
            clearInterval(this.loopGetTaskResult);
            return reject(error);
          }
        }, interval);
      });
    },

    async solveImage() {
      if (!this.imageToText.image) {
        this.$notification.error({message: 'Please choose an image'});
        return;
      }

      let gResponse = '';

      if (!this.isAdmin) {
        if (!this.grecaptchaComponent.rendered) {
          this.grecaptchaComponent.render();
          return;
        }

        gResponse = this.grecaptchaComponent.getResponse();

        if (!gResponse) {
          this.$notification.error({message: 'Please verify the captcha'});
          return;
        }
      }

      this.loading.solveImage = true;
      this.loadingText.solveImage = 'Processing';
      this.imageToText.result = undefined;
      try {
        const {data} = await TrialService.createImageTask({image: this.imageToText.image, recaptcha: gResponse});
        const {TaskId, Code, Message} = data;

        if (Code == 1) {
          throw new Error(Message);
        }

        await wait(500);
        // eslint-disable-next-line no-constant-condition
        const result = await this.whileTaskResult(TaskId);

        if (result.Status == 'SUCCESS') {
          this.imageToText.result = result.Data;
          this.$notification.success({message: 'Solve image captcha success!'});
        } else if (result.Status == 'ERROR') {
          this.imageToText.result = result.Message;
          this.$notification.error({message: `Solve image captcha error: ${result.Message}`});
        }

        if (!this.isAdmin) {
          this.grecaptchaComponent.reset();
        }
      }
      catch(error) {
        if (error?.response?.status == 413 || (error?.name == "AxiosError" && error?.code == "ERR_NETWORK")) {
          this.$notification.error({message: 'Payload too large, please try again'});
        } else {
          const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

          this.$notification.error({message});
          this.imageToText.result = message;

          if (message.includes('Unauthorized')) {
            this.$router.push({name: 'LoginPage'});
          }
        }
        
        if (!this.isAdmin) {
          this.grecaptchaComponent.reset();
        }
      }
      this.loading.solveImage = false;
      this.loadingText.solveImage = 'Solve Captcha';
    },

    async solveFuncaptcha() {
      if (!this.funcaptcha.sitekey) {
        this.$notification.error({message: 'Please choose a Funcaptcha site key'});
        return;
      }

      let gResponse = '';

      if (!this.isAdmin) {
        if (!this.grecaptchaComponent2.rendered) {
          this.grecaptchaComponent2.render();
          return;
        }

        gResponse = this.grecaptchaComponent2.getResponse();

        if (!gResponse) {
          this.$notification.error({message: 'Please verify the captcha'});
          return;
        }
      }

      this.loading.solveFuncaptcha = true;
      this.loadingText.solveFuncaptcha = 'Processing';
      this.funcaptcha.result = undefined;
      try {
        const {data} = await TrialService.createFuncaptchaTask({sitekey: this.funcaptcha.sitekey.trim(), recaptcha: gResponse});
        const {TaskId, Code, Message} = data;

        if (Code == 1) {
          throw new Error(Message);
        }

        await wait(500);
        // eslint-disable-next-line no-constant-condition
        const result = await this.whileTaskResult(TaskId);

        if (result.Status == 'SUCCESS') {
          this.funcaptcha.result = result.Data.Token;
          this.$notification.success({message: 'Solve funcaptcha captcha success!'});
        } else if (result.Status == 'ERROR') {
          this.funcaptcha.result = result.Message;
          this.$notification.error({message: `Solve funcaptcha captcha error: ${result.Message}`});
        }

        if (!this.isAdmin) {
          this.grecaptchaComponent2.reset();
        }
      }
      catch(error) {
        const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

        this.$notification.error({message});
        this.funcaptcha.result = message;

        if (message.includes('Unauthorized')) {
          this.$router.push({name: 'LoginPage'});
        }
        
        if (!this.isAdmin) {
          this.grecaptchaComponent2.reset();
        }
      }
      this.loading.solveFuncaptcha = false;
      this.loadingText.solveFuncaptcha = 'Solve Captcha';
    }
  }
}
</script>