import {SET_TRANSACTION_STATISTIC, SET_ACCOUNT_STATISTIC, SET_CAPTCHA_SOLVED_STATISTIC} from './types';

const state = {
  transactions: [],
  accounts: [],
  captchaSolved: [],
};

const mutations = {
  [SET_TRANSACTION_STATISTIC](state, transactions) {
    state.transactions = transactions;
  },
  [SET_ACCOUNT_STATISTIC](state, accounts) {
    state.accounts = accounts;
  },
  [SET_CAPTCHA_SOLVED_STATISTIC](state, captchaSolved) {
    state.captchaSolved = captchaSolved;
  }
};

import * as getters from "./getters";
import * as actions from "./actions";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};