<template>
  <div>
    <a-button class="bank-btn" @click="depositType = DepositTypes.ACB" style="background-color: #c8fff9cc">
      <img src="/images/acb.svg">
    </a-button>
    <br><br>
    <a-form class="submit-form" v-if="depositType" :form="form" @submit="handleSubmit">
      <a-form-item v-if="depositAmountUsd">
        <a-alert :message="`You will receive ~ $${depositAmountUsd}`" type="info" show-icon />
      </a-form-item>
      <a-form-item>
        <a-input
          v-decorator="[
            'amount',
            { rules: [
              { required: true, message: 'Please enter amount!' },
              { message: `Amount must be at least ${currentDepositInfo.minpay} VND`, validator: (rule, value) => Number(value) >= currentDepositInfo.minpay }
            ] },
          ]"
          type="number"
          :addon-before="`[${depositType}]`"
          addon-after="VND"
          placeholder="Amount"
          @change="caculateAmount"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-alert message="The exchange rate from US dollars to Vietnamese dong is 25,000!" type="warning" show-icon />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit" class="submit-form-button">
          <a-icon type="qrcode" /> Pay Invoice
        </a-button>
      </a-form-item>
      <a-modal v-model="payInvoiceVisible" :destroyOnClose="true" title="Scan QR to pay" @ok="payInvoiceVisible = false">
        <viet-qr-image :bank="depositType" :amount="currentDepositInfo.amount" :id="currentDepositInfo.id" :name="currentDepositInfo.name" :message="currentDepositInfo.message"></viet-qr-image>
      </a-modal>
    </a-form>
  </div>
</template>

<style>
.bank-btn {
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
}

.bank-btn img {
  max-width: 100%;
  height: 24px;
  display: block;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';
import VietQrImage from './VietQrImage.vue';

const DepositTypes = {
  ACB: 'ACB'
};

export default {
  components: {
    VietQrImage
  },
  data() {
    return {
      DepositTypes,
      depositType: undefined,
      payInvoiceVisible: false,
      depositAmount: undefined
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'bank-deposit' });
  },
  computed: {
    ...mapGetters('deposit', {
      depositInfo: "getDepositInfo"
    }),

    depositAmountUsd() {
      return parseFloat((0.00004 * this.depositAmount).toFixed(5));
    },

    currentDepositInfo() {
      const bankInfo = this.depositInfo.methods[this.depositType.toLowerCase()];

      return {
        amount: this.depositAmount,
        id: bankInfo.id,
        name: bankInfo.name,
        message: this.depositInfo.deposit_code,
        minpay: bankInfo.minpay
      }
    }
  },
  async mounted() {
    this.getDepositInfo();
  },
  methods: {
    ...mapActions('deposit', {
      getDepositInfo: "getInfo"
    }),

    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields(async (err, values) => {
        if (err) {
          return;
        }

        this.depositAmount = Number(values.amount);
        this.payInvoiceVisible = true;
      });
    },

    caculateAmount() {
      setTimeout(() => {
        this.depositAmount = Number(this.form.getFieldValue('amount'));
      }, 50);
    },
  },
}
</script>