<template>
  <a-card hoverable>
    <div class="extension-logo">
      <a-avatar :size="64" :src="images.chrome" />
    </div>
    <a-card-meta title="Chrome Extension">
      <template slot="description">
        We have browser extension for added convenience
      </template>
    </a-card-meta> 
    <br>
    <a @click="downloadForChrome">
      <a-icon type="download" /> Get for chrome
    </a>
  </a-card>
</template>

<script>
export default {
  data() {
    return {
      images: {
        chrome: '/images/chrome.png'
      },
      chromeDownloadUrl: 'https://drive.google.com/uc?id=1opKb9vQnnW7UGUkiAvcLDccQKnN4BQyR&export=download'
    }
  },

  methods: {
    downloadForChrome() {
      open(this.chromeDownloadUrl, "_blank");
    }
  }
}
</script>