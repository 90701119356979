import * as getters from "./getters";
import * as actions from "./actions";
import { SET_TRANSACTIONS, SET_PAGINATION } from "./types";

const state = {
  transactions: [],
  pagination: undefined
};

const mutations = {
  [SET_TRANSACTIONS](state, transactions) {
    state.transactions = transactions;
  },
  [SET_PAGINATION](state, pagination) {
    state.pagination = pagination;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};