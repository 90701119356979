import request from "./base";
const path = "/api/transactions";
const adminPath = "/api/admin/transactions";

export default {
  getTransactions: function (params) {
    return request().get(path + "/", {params});
  },
  getTransactionsFromAdmin: function (params) {
    return request().get(adminPath + "/", {params});
  },
  createTransactions: function (params) {
    return request().post(adminPath + "/", params);
  }
};
