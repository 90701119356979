var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-descriptions',{attrs:{"title":"User Info","layout":"vertical","bordered":""}},[_c('a-descriptions-item',{attrs:{"label":"Email"}},[_c('b',[_vm._v(_vm._s(_vm.email))])]),_c('a-descriptions-item',{attrs:{"label":"Role"}},[_c('b',[_vm._v(_vm._s(_vm.role))])]),_c('a-descriptions-item',{attrs:{"label":"Balance"}},[_c('span',{staticStyle:{"color":"green"}},[_vm._v("$"),_c('b',[_vm._v(_vm._s(parseFloat(_vm.balance.toFixed(5)) || 0))])])])],1),_c('br'),_c('div',{staticClass:"ant-descriptions-title"},[_vm._v("Change Password")]),_c('a-form',{staticClass:"submit-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'currentpassword',
          { rules: [
            { required: true, message: 'Please input your current Password!' },
            { pattern: /^[a-zA-Z0-9@]+$/, message: 'Current password must be a letter, number or @ character' },
            { min: 5, max: 25, message: 'Current password length must be from 5 to 25 characters' }
          ] },
        ]),expression:"[\n          'currentpassword',\n          { rules: [\n            { required: true, message: 'Please input your current Password!' },\n            { pattern: /^[a-zA-Z0-9@]+$/, message: 'Current password must be a letter, number or @ character' },\n            { min: 5, max: 25, message: 'Current password length must be from 5 to 25 characters' }\n          ] },\n        ]"}],attrs:{"placeholder":"Current Password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'newpassword',
          { rules: [
            { required: true, message: 'Please input your new Password!' },
            { pattern: /^[a-zA-Z0-9@]+$/, message: 'Password must be a letter, number or @ character' },
            { min: 5, max: 25, message: 'Password length must be from 5 to 25 characters' }
          ] },
        ]),expression:"[\n          'newpassword',\n          { rules: [\n            { required: true, message: 'Please input your new Password!' },\n            { pattern: /^[a-zA-Z0-9@]+$/, message: 'Password must be a letter, number or @ character' },\n            { min: 5, max: 25, message: 'Password length must be from 5 to 25 characters' }\n          ] },\n        ]"}],attrs:{"placeholder":"Password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'confirmpassword',
          { rules: [{ required: true, message: 'Please input your confirm Password!' }, { validator: _vm.comparePassword }] },
        ]),expression:"[\n          'confirmpassword',\n          { rules: [{ required: true, message: 'Please input your confirm Password!' }, { validator: comparePassword }] },\n        ]"}],attrs:{"placeholder":"Confirm Password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('google-recaptcha-v2',{ref:"grecaptcha-component"}),_c('a-form-item',[_c('a-button',{staticClass:"submit-form-button",attrs:{"type":"primary","html-type":"submit"}},[_c('a-icon',{attrs:{"type":"save"}}),_vm._v(" Submit ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }