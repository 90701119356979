import request from "./base";
const path = "/api/admin/statistic";

export default {
  getTransactionsStatistic: function (params) {
    return request().get(path + "/transactions", {params});
  },
  getAccountsStatistic: function (params) {
    return request().get(path + "/accounts", {params});
  },
  getCaptchaSolvedStatistic: function (params) {
    return request().get(path + "/captcha-solved", {params});
  }
};
