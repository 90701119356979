<template>
  <div>
    <h1 style="font-size: 1.5em"><font-awesome-icon class="mr-10" :icon="['fas', 'ticket']" /> List of Discount codes</h1>
    <br>
    <div id="filter">
      <a-input-group class="mr-10" compact style="display: inline-block; width: 300px; line-height: 32px">
        <a-input v-model="filter.discount_value[0]" addonBefore="Discount value" style=" width: 65%; text-align: center" placeholder="Min" />
        <a-input
          style=" width: 28px; border-left: 0; pointer-events: none; background: #fff"
          placeholder="-"
          disabled
        />
        <a-input v-model="filter.discount_value[1]" style="width: 25%; text-align: center; border-left: 0" placeholder="Max" />
      </a-input-group>

      <a-select v-model="filter.discount_type" placeholder="Type" class="mr-10" default-value="" allowClear style="width: 150px">
        <a-select-option value="" disabled>
          Type
        </a-select-option>
        <a-select-option value="fixed">
          <a-tag color="red"><a-icon type="lock" /> Fixed</a-tag>
        </a-select-option>
        <a-select-option value="percentage">
          <a-tag color="blue"><a-icon type="percentage" /> Percentage</a-tag>
        </a-select-option>
      </a-select>

      <a-input class="mr-10" v-model="filter.code" placeholder="Discount code" style="width: 250px">
        <span slot="addonBefore">Code</span>
      </a-input>

      <a-input class="mr-10" v-model="filter.description" placeholder="Description" style="width: 400px">
        <span slot="addonBefore">Description</span>
      </a-input>

      <a-input-group class="mr-10" compact style="display: inline-block; width: 350px; line-height: 32px">
        <a-input v-model="filter.transaction_range[0]" addonBefore="Condition range" style=" width: 62%; text-align: center" placeholder="Min" />
        <a-input
          style=" width: 28px; border-left: 0; pointer-events: none; background: #fff"
          placeholder="-"
          disabled
        />
        <a-input v-model="filter.transaction_range[1]" style="width: 28%; text-align: center; border-left: 0" placeholder="Max" />
      </a-input-group>

      <span>Expired at: </span>
      <a-input-group class="mr-10" compact style="display: inline-block; width: 300px; line-height: 32px">
        <a-range-picker v-model="filter.expired_at" />
      </a-input-group>

      <span>Started at: </span>
      <a-input-group class="mr-10" compact style="display: inline-block; width: 300px; line-height: 32px">
        <a-range-picker v-model="filter.started_at" />
      </a-input-group>

      <span>Created at: </span>
      <a-input-group class="mr-10" compact style="display: inline-block; width: 300px; line-height: 32px">
        <a-range-picker v-model="filter.created_at" />
      </a-input-group>

      <a-input-group class="mr-10" compact style="display: inline-block; width: 260px; line-height: 32px">
        <a-input v-model="filter.quantity[0]" addonBefore="Quantity" style=" width: 60.5%; text-align: center" placeholder="Min" />
        <a-input
          style=" width: 28px; border-left: 0; pointer-events: none; background: #fff"
          placeholder="-"
          disabled
        />
        <a-input v-model="filter.quantity[1]" style="width: 29.5%; text-align: center; border-left: 0" placeholder="Max" />
      </a-input-group>

      <a-input-group class="mr-10" compact style="display: inline-block; width: 260px; line-height: 32px">
        <a-input v-model="filter.usage[0]" addonBefore="Usage" style=" width: 57.5%; text-align: center" placeholder="Min" />
        <a-input
          style=" width: 28px; border-left: 0; pointer-events: none; background: #fff"
          placeholder="-"
          disabled
        />
        <a-input v-model="filter.usage[1]" style="width: 32.5%; text-align: center; border-left: 0" placeholder="Max" />
      </a-input-group>

      <a-button type="primary" @click="loadDiscounts"><a-icon type="filter" /> Filter</a-button>
    </div>
    <br>
    <div id="actions">
      <a-button class="mr-10" @click="showCreateModal()" type="primary" style="color: #1ba805; background-color: #f0fff3; border-color: #91ed5f"><a-icon type="plus-circle">
        </a-icon>Create
      </a-button>
      <a-button v-if="tableSelectedRowKeys.length > 0" @click="handleDeleteSelected"
        :loading="loading.deleteSelected" type="primary" style="color: #f5222d; background-color: #fff1f0; border-color: #ffa39e">
        <a-icon type="delete"></a-icon>Delete
      </a-button>
    </div>
    <br>
    <a-table 
      :data-source="tableData" 
      :columns="columns" 
      :pagination="tablePagination"
      :loading="loading.loadDiscounts"
      :rowSelection="{
        selectedRowKeys: tableSelectedRowKeys,
        onChange: tableSelectedChange
      }"
      :scroll="{x: 1400, y: tableHeight}"
    >
      <span class="ellipsis" slot="code" slot-scope="code">
        <span>{{code}}</span><a-button @click="copyValue(code)" type="link" size="small" icon="copy" />
      </span>
      <a-tag slot="discount_value" slot-scope="discount_value, record" :color="record.discount_type == 'fixed' ? 'red' : 'blue'">
        {{discount_value}} {{record.discount_type == 'fixed' ? '$' : '%'}}
      </a-tag>
      <span slot="conditions" slot-scope="conditions"><i><u>${{conditions.transaction.min}} - ${{conditions.transaction.max}}</u></i></span>
      <span slot="usage" slot-scope="usage, record">
        <b :style="{color: ((record.quantity - record.usage <= 0) && (record.quantity != -1)) ? 'red' : 'blue'}">
          {{record.usage}} </b>
            /
          {{record.quantity == -1 ? 'unlimited' : record.quantity}}
      </span>
      <span slot="expired_at" slot-scope="expired_at, record" :style="{color: record.is_expired ? 'red' : 'rgba(0, 0, 0, 0.65)'}">{{new Date(expired_at).toLocaleString()}}</span>
      <span slot="started_at" slot-scope="started_at, record" :style="{color: record.is_started ? 'rgba(0, 0, 0, 0.65)' : 'red'}">{{new Date(started_at).toLocaleString()}}</span>
      <a slot="action" slot-scope="action, record"
        href="javascript:;" style="margin-left:15px" @click="showUpdateModal(record)"><a-icon type="edit" />
      </a>
    </a-table>

    <a-modal 
      width="800px" 
      v-model="visibleModal.formData" 
      :dialog-style="{ top: '45px' }"  
      :destroyOnClose="true" 
      :title="action_type == 'create' ? 'Create discount' : 'Update discount'" 
      :footer="null"
    >
      <form-data v-if="action_type == 'create'" @close="visibleModal.formData = false"></form-data>
      <form-data v-if="action_type == 'update'" :record="recordToUpdate" @close="visibleModal.formData = false"></form-data>
    </a-modal>
  </div>
</template>

<style scoped>
#filter {
  margin-bottom: 10px;
}

#filter .mr-10 {
  margin-right: 10px;
  margin-bottom: 10px;
}

.ellipsis span{
  display: inline-block;
  width: 10ch; /* Limit to 5 characters */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom; /* Align ellipsis properly */
}

/* Mobile Styles */
@media (max-width: 768px) {
  #filter .mr-10 {
    width: 100%!important;
  }
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import FormData from './promotion/FormData.vue';

const columns = [
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    scopedSlots: { customRender: 'code' },
    width: 150
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 250
  },
  {
    title: 'Used / Quantity',
    dataIndex: 'usage',
    key: 'usage',
    scopedSlots: { customRender: 'usage' },
    width: 180
  },
  {
    title: 'Transaction value to apply',
    dataIndex: 'conditions',
    key: 'conditions',
    scopedSlots: { customRender: 'conditions' },
    width: 200
  },
  {
    title: 'Discount value',
    dataIndex: 'discount_value',
    key: 'discount_value',
    scopedSlots: { customRender: 'discount_value' },
    width: 100
  },
  {
    title: 'Started at',
    dataIndex: 'started_at',
    key: 'started_at',
    scopedSlots: { customRender: 'started_at' },
  },
  {
    title: 'Expired at',
    dataIndex: 'expired_at',
    key: 'expired_at',
    scopedSlots: { customRender: 'expired_at' },
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt'
  },
  {
    title: 'Action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  },
];

const pageSize = 10;

export default {
  components: {
    FormData
  },

  data() {
    return {
      filter: {
        page: 1,
        page_size: pageSize,
        transaction_range: {
          0: undefined,
          1: undefined
        },
        discount_value: {
          0: undefined,
          1: undefined
        },
        quantity: {
          0: undefined,
          1: undefined
        },
        usage: {
          0: undefined,
          1: undefined
        },
      },
      columns,
      visibleModal: {
        create: false,
        update: false,
        formData: false
      },
      loading: {
        updateDiscount: false,
        loadDiscounts: false,
        deleteSelected: false,
      },
      tableSelectedRowKeys: [],
      recordToUpdate: undefined,
      action_type: 'create',
      tablePagination: {
        total: 0,
        pageSize: pageSize,
        current: 1,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
        onChange: this.handleTableChange,
        onShowSizeChange: this.handlePageSizeChange,
        showTotal: (total, range) => {
          return `${range[0]} - ${range[1]} of ${total}`;
        }
      },
    }
  },
  async mounted() {
    this.loadDiscounts();
  },
  computed: {
    ...mapGetters('promotion', {
      discounts: 'getDiscounts',
      pagination: 'getPagination'
    }),

    tableHeight() {
      return Math.round(innerHeight / 2);
    },

    tableData() {
      if (!this.discounts) {
        return [];
      }

      if (this.discounts instanceof Array) {
        return this.discounts.map(discount => {
          discount.key = discount._id;
          discount.createdAt = new Date(discount.createdAt).toLocaleString();

          return discount;
        });
      }

      return [];
    },

    optimizedFilter() {
      const filter = {};

      for (let key in this.filter) {
        if (this.filter[key]) {
          if (typeof this.filter[key] == 'string' || typeof this.filter[key] == 'number') {
            filter[key] = this.filter[key];
            continue;
          }

          if (typeof this.filter[key] == 'object' && this.filter[key][0] && this.filter[key][1]) {
            if (key == 'created_at' || key == 'expired_at' || key == 'started_at') {
              filter[key] = [
                this.filter[key][0].startOf('day').toISOString(),
                this.filter[key][1].endOf('day').toISOString(),
              ].join();
              continue;
            }

            filter[key] = [this.filter[key][0], this.filter[key][1]].join();
            continue;
          }
        }
      }

      return filter;
    }
  },
  methods: {
    ...mapActions('promotion', {
      getDiscounts: 'getDiscounts',
      deleteDiscounts: 'deleteDiscounts'
    }),

    tableSelectedChange(selectedRowKeys) {
      this.tableSelectedRowKeys = selectedRowKeys;
    },

    async copyValue(value) {
      await navigator.clipboard.writeText(value);
      this.$message.success("Copied");
    },

    async loadDiscounts() {
      this.loading.loadDiscounts = true;

      try {
        await this.getDiscounts(this.optimizedFilter);
        this.tablePagination.total = this.pagination.total;
        this.tablePagination.pageSize = this.pagination.pageSize;
      }
      catch(error) {
        const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

        this.$notification.error({message});

        if (message.includes('Unauthorized')) {
          this.$router.push({name: 'LoginPage'});
        }
      }

      this.loading.loadDiscounts = false;
    },

    async handleTableChange(page) {
      this.filter.page = page;
      await this.loadDiscounts();
      this.tablePagination.current = page;
    },

    handleDeleteSelected() {
      const deleteCount = this.tableSelectedRowKeys.length;

      this.$confirm({
        title: `Are you sure you want to delete these ${deleteCount} discount codes?`,
        onOk: async () => {
          this.loading.deleteSelected = true;
          try {
            await this.deleteDiscounts({discount_ids: this.tableSelectedRowKeys});
            this.tableSelectedRowKeys = [];
            this.$notification.success({message: `Deleted ${deleteCount} discount codes!`});
          }
          catch(error) {
            const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

            this.$notification.error({message});

            if (message.includes('Unauthorized')) {
              this.$router.push({name: 'LoginPage'});
            }
          }
          this.loading.deleteSelected = false;
        }
      });
    },

    showUpdateModal(record) {
      this.action_type = 'update';
      this.recordToUpdate = { ...record };
      this.visibleModal.formData = true;
    },

    showCreateModal() {
      this.action_type = 'create';
      this.visibleModal.formData = true;
    }
  },
}
</script>