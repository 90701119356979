<template>
  <a-card title="Login" class="root-card">
    <a-form class="login-form" :form="form" @submit="handleLogin">
      <a-form-item>
        <a-input
          v-decorator="[
            'email',
            { rules: [
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Please enter a valid email address!' }
            ] },
          ]"
          placeholder="Email"
        >
          <a-icon slot="prefix" type="mail" style="color: rgba(0,0,0,.25)" />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input-password
          v-decorator="[
            'password',
            { rules: [
              { required: true, message: 'Please input your Password!' },
              { pattern: /^[a-zA-Z0-9@]+$/, message: 'Password must be a letter, number or @ character' },
              { min: 5, max: 25, message: 'Password length must be from 5 to 25 characters' }
            ] },
          ]"
          placeholder="Password"
        >
          <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
        </a-input-password>
      </a-form-item>
      <a-form-item>
        <a-checkbox
          v-decorator="[
            'remember',
            {
              valuePropName: 'checked',
              initialValue: true,
            },
          ]"
        >
          Remember
        </a-checkbox>
        <router-link class="login-form-forgot" :to="{name: 'ForgotPage'}">
          Forgot password
        </router-link>
        <google-recaptcha-v2 ref="grecaptcha-component"></google-recaptcha-v2>
        <a-button type="primary" html-type="submit" :loading="loginLoading" class="login-form-button">
          <a-icon type="login" /> Log in
        </a-button>
        Don't have an account?
        <router-link :to="{name: 'RegisterPage'}">
          Sign up
        </router-link>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<style scoped>
.login-form {
  width: 300px;
  margin: 0 auto;
}
.login-form .ant-form-item {
  margin-bottom: 10px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

@media screen and (max-width: 480px) {
  .login-form {
    width: 100%;
  }

  .login-form .ant-row {
    width: 100%;
  }
}
</style>

<script>
import {mapGetters, mapActions} from 'vuex';
import GoogleRecaptchaV2 from '../utils/GoogleRecaptchaV2.vue';

export default {
  components: {
    GoogleRecaptchaV2
  },  
  data() {
    return {
      loginLoading: false
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'login' });
  },
  mounted() {
    if (this.$route.params.email) {
      this.form.setFieldsValue({
        email: this.$route.params.email,
        password: this.$route.params.password
      });
    }
  },
  computed: {
    ...mapGetters("auth", {
      role: "getRole"
    }),

    grecaptchaComponent() {
      return this.$refs['grecaptcha-component'];
    }
  },
  methods: {
    ...mapActions('auth', {
      login: 'login'
    }),

    handleLogin(e) {
      e.preventDefault();

      if (!this.grecaptchaComponent.rendered) {
        this.grecaptchaComponent.render();
        return;
      }

      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (!this.grecaptchaComponent.getResponse()) {
            this.$notification.error({message: 'Please verify the captcha'});
            return;
          }

          const {email, password, remember} = values;
          this.loginLoading = true;
          try {
            const {message} = await this.login({email, password, remember, recaptcha: this.grecaptchaComponent.getResponse()});
            this.$notification.success({message});
            this.$router.push({name: this.role == 'member' ? 'MemberProfilePage' : 'AdminProfilePage'});
          }
          catch(error) {
            const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

            this.$notification.error({message});

            if (message.includes('Unauthorized')) {
              this.$router.push({name: 'LoginPage'});
            }
            
            this.grecaptchaComponent.reset();
          }
          this.loginLoading = false;
        }
      });
    }
  }
}
</script>
