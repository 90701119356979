<template>
  <div>
    <a-alert :message="`After the transfer is successful, you will receive $${depositAmountUsd} within 3-5 minutes. Please check your balance in Profile page`" type="info" show-icon />
    <br>
    <a-descriptions layout="vertical" bordered>
      <a-descriptions-item label="Bank ID">
        <b>{{ id }}</b><a-button @click="copyValue(id)" type="link" size="small" icon="copy" />
      </a-descriptions-item>
      <a-descriptions-item label="Amount">
        <b>{{ currencyFormatter.format(amount) }}</b><a-button @click="copyValue(amount)" type="link" size="small" icon="copy" />
      </a-descriptions-item>
      <a-descriptions-item label="Message">
        <b>{{ message }}</b><a-button @click="copyValue(message)" type="link" size="small" icon="copy" />
      </a-descriptions-item>
    </a-descriptions>
    <a-alert v-if="!imageLoaded" message="QR loading..." type="warning" show-icon />
    <img :src="imageSrc" style="width: 100%" @load="onImageLoaded" alt="Dynamic Image"/>
  </div>
</template>

<script>
export default {
  props: {
    bank: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    amount: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      imageLoaded: false,
      currencyFormatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'VND',
      })
    }
  },
  computed: {
    depositAmountUsd() {
      return parseFloat((0.00004 * this.amount).toFixed(5));
    },

    imageSrc() {
      const url = `https://img.vietqr.io/image/${this.bank}-${this.id}-print.png`;

      const searchParams = new URLSearchParams({
        amount: this.amount,
        accountName: this.name,
        addInfo: this.message
      });

      return `${url}?${searchParams.toString()}`;
    }
  },
  methods: {
    onImageLoaded() {
      this.imageLoaded = true;
    },
    async copyValue(value) {
      await navigator.clipboard.writeText(value);
      this.$message.success("Copied");
    }
  }
}
</script>