import {SET_DEPOSIT_INFO} from './types';

/* eslint-disable no-unused-vars */
import DepositService from '@/services/deposit';

export async function getInfo({commit}) {
  const {data} = await DepositService.getInfo();
  commit(SET_DEPOSIT_INFO, data.data);
  return data.data;
}

export async function createBinanceTransaction({commit}, params) {
  const {data} = await DepositService.createBinanceTransaction(params);
  return data.data;
}

export async function getBinanceTransaction({commit}) {
  const {data} = await DepositService.getBinanceTransaction();
  return data.data;
}

export async function createRegisterTransaction({commit}, params) {
  const {data} = await DepositService.createRegisterTransaction(params);
  return data.data;
}

export async function createRenewTransaction({commit}, params) {
  const {data} = await DepositService.createRenewTransaction(params);
  return data.data;
}