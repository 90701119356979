<template>
  <a-card title="Admin Area" class="root-card pd-lr-zero">
    <a-button
      class="menu-toggle-button"
      @click="isMenuVisible = !isMenuVisible"
    >
      <a-icon :type="isMenuVisible ? 'menu-fold' : 'menu-unfold'" />
    </a-button>
    <a-layout style="padding: 0; background: #fff">
      <a-layout-sider class="layout-sider" v-if="isMenuVisible" width="200" style="background: #fff">
        <a-menu
          mode="inline"
          :default-selected-keys="[this.$route.name]"
          style="height: 100%"
          :selected-keys="[this.$route.name]"
          @click="onSelectMenuItem"
        >
          <a-menu-item key="AdminProfilePage">
            <router-link :to="{name: 'AdminProfilePage'}">
              <a-icon type="profile" />Profile
            </router-link>
          </a-menu-item>
          <a-menu-item key="AdminStatisticPage">
            <router-link :to="{name: 'AdminStatisticPage'}">
              <a-icon type="bar-chart" />Statistic
            </router-link>
          </a-menu-item>
          <a-menu-item key="AdminAccountPage">
            <router-link :to="{name: 'AdminAccountPage'}">
              <a-icon type="user" />Accounts
            </router-link>
          </a-menu-item>
          <a-menu-item key="AdminPackagePage">
            <router-link :to="{name: 'AdminPackagePage'}">
              <font-awesome-icon class="mr-10" :icon="['fas', 'cube']" />Packages
            </router-link>
          </a-menu-item>
          <a-menu-item key="AdminPromotionPage">
            <router-link :to="{name: 'AdminPromotionPage'}">
              <font-awesome-icon class="mr-10" :icon="['fas', 'ticket']" />Promotion
            </router-link>
          </a-menu-item>
          <a-menu-item key="AdminTransactionPage">
            <router-link :to="{name: 'AdminTransactionPage'}">
              <a-icon type="transaction" />Transactions
            </router-link>
          </a-menu-item>
          <a-menu-item key="DevtoolsPage">
            <router-link :to="{name: 'DevtoolsPage'}">
              <a-icon type="tool" />Devtools
            </router-link>
          </a-menu-item>
          <a-menu-item key="Space" style="height: 10px" disabled>
            <hr>
          </a-menu-item>
          <a-menu-item key="LogoutPage" @click="logout">
            <a-icon type="logout" /> Logout
          </a-menu-item>
        </a-menu>
      </a-layout-sider>
      <a-layout-content v-if="!isMobile || !isMenuVisible" :style="{ padding: '0 24px', minHeight: '280px' }">
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </a-card>
</template>

<style scoped>
.menu-toggle-button {
  display: none;
  margin-left: 24px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .menu-toggle-button {
    display: block;
    margin-bottom: 24px;
  }
  .ant-menu {
    width: 100% !important;
  }
  .layout-sider {
    width: 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      isMenuVisible: true
    };
  },
  mounted() {
    if (!this.role) {
      this.$router.push({name: 'LoginPage'});
      return;
    }

    if (this.role != 'admin') {
      this.$router.push({name: 'MemberProfilePage'});
    }

    this.isMenuVisible = !this.isMobile;
  },
  computed: {
    ...mapGetters("auth", {
      role: "getRole"
    }),

    isMobile() {
      return innerWidth <= 768;
    }
  },
  methods: {
    ...mapActions("auth", {
      removeSession: "removeSession"
    }),

    logout() {
      this.$confirm({
        title: 'Are you sure you want to log out?',
        content: 'When clicked the OK button, You will be logged out of the website.',
        onOk: () => {
          this.removeSession();
          this.$router.push({name: 'LoginPage'});
        }
      });
    },

    onSelectMenuItem({ key }) {
      if (key == 'LogoutPage') {
        return;
      }

      if (this.isMobile) {
        this.isMenuVisible = false;
      }
    }
  }
}
</script>