var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{staticClass:"root-card",attrs:{"title":"Recovery your password"}},[_c('a-form',{staticClass:"forgot-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleRecovery}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          { rules: [
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Please enter a valid email address!' }
          ] },
        ]),expression:"[\n          'email',\n          { rules: [\n            { required: true, message: 'Please input your email!' },\n            { type: 'email', message: 'Please enter a valid email address!' }\n          ] },\n        ]"}],attrs:{"placeholder":"Email"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1),(_vm.disabled.recovery)?_c('a-form-item',[_c('a-alert',{attrs:{"message":"If you do not receive the password recovery email, you can try resending it after 60s!","type":"info","show-icon":""}})],1):_vm._e(),_c('google-recaptcha-v2',{ref:"grecaptcha-component"}),_c('router-link',{attrs:{"to":{name: 'LoginPage'}}},[_c('a-icon',{attrs:{"type":"caret-left"}}),_vm._v(" Back to Log in ")],1),_c('a-form-item',[_c('a-button',{staticClass:"forgot-form-button",attrs:{"type":"primary","loading":_vm.loading.recovery,"disabled":_vm.disabled.recovery,"html-type":"submit"}},[_c('a-icon',{attrs:{"type":"play-circle"}}),_vm._v(" Send ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }