export function getAccessToken(state) {
  return state.accessToken;
}

export function isLoggedIn(state) {
  return !!state.email;
}

export function getEmail(state) {
  return state.email;
}

export function getRole(state) {
  return state.role;
}

export function getEmailUser(state) {
  if (!state.email || typeof state.email != 'string') {
    return null;
  }

  return state.email.split('@')[0];
}