import Vue from 'vue';
import App from './App.vue';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import router from './router';
import store from './store';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import { faCode, faHeadset, faCoins, faCube, faTicket } from '@fortawesome/free-solid-svg-icons';

/* add icons to the library */
library.add([faCode, faHeadset, faCoins, faCube, faTicket]);

Vue.config.productionTip = false;

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(Antd);

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app');