import request from "./base";
const path = "/register";

export default {
  sendVerificationCode: function (params) {
    return request().post(path + "/send-verification-code", params);
  },
  createAccount: function (params) {
    return request().post(path + "/", params);
  }
};
