<template>
  <div ref="grecaptcha-element"></div>
</template>

<script>
import config from '@/config/index.config';

export default {
  data() {
    return {
      rendered: false
    };
  },
  async mounted() {
    this.initialize();
  },
  computed: {
    initialized() {
      return window.__grecaptcha_initialized == true;
    }
  },
  methods: {
    initialize() {
      if (this.initialized) {
        return;
      }

      return new Promise((resolve) => {
        const script = document.createElement('script');

        window.__grecaptcha_onload = function() {
          window.__grecaptcha_initialized = true;
          resolve(true);
        }

        script.setAttribute("type", "text/javascript");
        script.async = true;
        script.defer = true;
        script.setAttribute("src", "https://www.google.com/recaptcha/api.js?onload=__grecaptcha_onload&render=explicit");

        (document.body || document.documentElement).appendChild(script);
      });
    },

    render() {
      window.grecaptcha.render(this.$refs['grecaptcha-element'], {
        sitekey: config.gRecaptcha.siteKey
      });
      this.rendered = true;
    },

    reset() {
      window.grecaptcha.reset();
    },

    getResponse() {
      try {
        return window.grecaptcha.getResponse();
      }
      catch(error) {
        return "";
      }
    }
  },
}
</script>
