<template>
  <div>
    <a-row :gutter="[24,8]">
      <a-col :span="isMobile ? 24 : 12">
        <a-card :loading="loading.account" title="Accounts Statistic">
          <div id="filter">
            <a-select v-model="filter.account.view_by" placeholder="View" class="mr-10" default-value="day" style="width: 110px">
              <a-select-option value="day">
                <a-tag color="blue">By day</a-tag>
              </a-select-option>
              <a-select-option value="month">
                <a-tag color="orange">By month</a-tag>
              </a-select-option>
            </a-select>
            <a-range-picker
              v-if="filter.account.view_by == 'month'"
              class="mr-10"
              :placeholder="['Start month', 'End month']"
              format="YYYY-MM"
              v-model="filter.account.created_time"
              :mode="['month', 'month']"
              @panelChange="(value) => this.filter.account.created_time = value"
              @change="(value) => this.filter.account.created_time = value"
            />
            <a-input-group v-if="filter.account.view_by == 'day'" class="mr-10" compact style="display: inline-block; width: 350px; line-height: 32px">
              <a-range-picker v-model="filter.account.created_time" />
            </a-input-group>
            <a-button type="primary" @click="loadAccountStatistic"><a-icon type="filter" /> Filter</a-button>
          </div>
          <br>
          <Bar
            :options="{responsive: true}"
            :data="accountsChartData"
          />
        </a-card>
      </a-col>
      <a-col :span="isMobile ? 24 : 12">
        <a-card :loading="loading.transaction" title="Transactions Statistic">
          <div id="filter">
            <a-select v-model="filter.transaction.view_by" placeholder="View" class="mr-10" style="width: 110px">
              <a-select-option value="day">
                <a-tag color="blue">By day</a-tag>
              </a-select-option>
              <a-select-option value="month">
                <a-tag color="orange">By month</a-tag>
              </a-select-option>
            </a-select>
            <a-range-picker
              v-if="filter.transaction.view_by == 'month'"
              class="mr-10"
              :placeholder="['Start month', 'End month']"
              format="YYYY-MM"
              v-model="filter.transaction.created_time"
              :mode="['month', 'month']"
              @panelChange="(value) => this.filter.transaction.created_time = value"
              @change="(value) => this.filter.transaction.created_time = value"
            />
            <a-input-group v-if="filter.transaction.view_by == 'day'" class="mr-10" compact style="display: inline-block; width: 350px; line-height: 32px">
              <a-range-picker v-model="filter.transaction.created_time" />
            </a-input-group>
            <a-select v-model="filter.transaction.type" placeholder="Type" class="mr-10" style="width: 110px">
              <a-select-option value="credit">
                <a-tag color="green"><a-icon type="plus-circle" /> Plus</a-tag>
              </a-select-option>
              <a-select-option value="debit">
                <a-tag color="red"><a-icon type="minus-circle" /> Minus</a-tag>
              </a-select-option>
            </a-select>
            <a-input class="mr-10" v-model="filter.transaction.email" placeholder="Email" style="width: 300px">
              <span slot="addonBefore">Email</span>
            </a-input>
            <a-input class="mr-10" v-model="filter.transaction.account_id" placeholder="Account ID" style="width: 300px">
              <span slot="addonBefore">Account ID</span>
            </a-input>
            <a-select v-model="filter.transaction.gateway" placeholder="Gateway" class="mr-10" default-value="" allowClear style="width: 110px">
              <a-select-option value="" disabled>
                Gateway
              </a-select-option>
              <a-select-option value="acb">
                <a-tag style="background: rgba(200, 255, 249, 0.8); color: rgb(31 65 155)">ACB</a-tag>
              </a-select-option>
              <a-select-option value="binance">
                <a-tag style="background: rgb(24, 26, 32); color: rgb(240 185 11)">Binance</a-tag>
              </a-select-option>
            </a-select>
            <a-select v-model="filter.transaction.captcha_type" placeholder="Captcha Type" class="mr-10" default-value="" allowClear style="width: 200px">
              <a-select-option value="" disabled>
                Captcha Type
              </a-select-option>
              <a-select-option value="funcaptcha">
                <a-avatar src="/images/funcaptcha.png" style="margin-right: 5px"></a-avatar>Funcaptcha
              </a-select-option>
              <a-select-option value="image">
                <a-avatar src="/images/itt.png" style="margin-right: 5px"></a-avatar>Image To Text
              </a-select-option>
            </a-select>
            <a-button type="primary" @click="loadTransactionStatistic"><a-icon type="filter" /> Filter</a-button>
          </div>
          <br>
          <Bar
            :options="{responsive: true}"
            :data="transactionsChartData"
          />
        </a-card>
      </a-col>
      <a-col :span="isMobile ? 24 : 12">
        <a-card :loading="loading.captchaSolved" title="Captcha Solved Statistic">
          <div id="filter">
            <a-select v-model="filter.captchaSolved.view_by" placeholder="View" class="mr-10" style="width: 110px">
              <a-select-option value="day">
                <a-tag color="blue">By day</a-tag>
              </a-select-option>
              <a-select-option value="month">
                <a-tag color="orange">By month</a-tag>
              </a-select-option>
            </a-select>
            <a-range-picker
              v-if="filter.captchaSolved.view_by == 'month'"
              class="mr-10"
              :placeholder="['Start month', 'End month']"
              format="YYYY-MM"
              v-model="filter.captchaSolved.created_time"
              :mode="['month', 'month']"
              @panelChange="(value) => this.filter.captchaSolved.created_time = value"
              @change="(value) => this.filter.captchaSolved.created_time = value"
            />
            <a-input-group v-if="filter.captchaSolved.view_by == 'day'" class="mr-10" compact style="display: inline-block; width: 350px; line-height: 32px">
              <a-range-picker v-model="filter.captchaSolved.created_time" />
            </a-input-group>
            <a-button type="primary" @click="loadCaptchaSolvedStatistic"><a-icon type="filter" /> Filter</a-button>
          </div>
          <br>
          <Bar
            :options="{responsive: true}"
            :data="captchaSolvedChartData"
          />
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<style scoped>
#filter {
  margin-bottom: 10px;
}

#filter .mr-10 {
  margin-right: 10px;
  margin-bottom: 10px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  #filter .mr-10 {
    width: 100%!important;
  }
}
</style>

<script>
import moment from 'moment';
import {mapActions, mapGetters} from 'vuex';
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'BarChart',
  components: { Bar },
  data() {
    return {
      filter: {
        transaction: {
          view_by: 'day',
          created_time: [moment().subtract(7, 'days'), moment()],
          type: 'credit'
        },
        account: {
          view_by: 'day',
          created_time: [moment().subtract(7, 'days'), moment()]
        },
        captchaSolved: {
          view_by: 'day',
          created_time: [moment().subtract(7, 'days'), moment()]
        }
      },
      value: [],
      loading: {
        transaction: false,
        account: false,
        captchaSolved: false
      }
    }
  },
  mounted() {
    this.loadStatistic();
  },
  computed: {
    ...mapGetters('statistic', {
      transactionsStatistic: 'getTransactionsStatistic',
      accountsStatistic: 'getAccountsStatistic',
      captchaSolvedStatistic: "getCaptchaSolvedStatistic"
    }),

    isMobile() {
      return innerWidth <= 768;
    },

    transactionStatisticFilter() {
      const filter = {};
      const baseFilter = this.filter.transaction;

      for (let key in baseFilter) {
        if (baseFilter[key]) {
          if (typeof baseFilter[key] == 'string' || typeof baseFilter[key] == 'number') {
            filter[key] = baseFilter[key];
            continue;
          }

          if (typeof baseFilter[key] == 'object' && baseFilter[key][0] && baseFilter[key][1]) {
            if (key == 'created_time') {
              filter[key] = [
                baseFilter[key][0].startOf('day').toISOString(),
                baseFilter[key][1].endOf('day').toISOString(),
              ].join();
              continue;
            }

            filter[key] = [baseFilter[key][0], baseFilter[key][1]].join();
            continue;
          }
        }
      }

      return filter;
    },

    accountStatisticFilter() {
      const filter = {};
      const baseFilter = this.filter.account;

      for (let key in baseFilter) {
        if (baseFilter[key]) {
          if (typeof baseFilter[key] == 'string' || typeof baseFilter[key] == 'number') {
            filter[key] = baseFilter[key];
            continue;
          }

          if (typeof baseFilter[key] == 'object' && baseFilter[key][0] && baseFilter[key][1]) {
            if (key == 'created_time') {
              filter[key] = [
                baseFilter[key][0].startOf('day').toISOString(),
                baseFilter[key][1].endOf('day').toISOString(),
              ].join();
              continue;
            }

            filter[key] = [baseFilter[key][0], baseFilter[key][1]].join();
            continue;
          }
        }
      }

      return filter;
    },

    captchaSolvedStatisticFilter() {
      const filter = {};
      const baseFilter = this.filter.captchaSolved;

      for (let key in baseFilter) {
        if (baseFilter[key]) {
          if (typeof baseFilter[key] == 'string' || typeof baseFilter[key] == 'number') {
            filter[key] = baseFilter[key];
            continue;
          }

          if (typeof baseFilter[key] == 'object' && baseFilter[key][0] && baseFilter[key][1]) {
            if (key == 'created_time') {
              filter[key] = [
                baseFilter[key][0].startOf('day').toISOString(),
                baseFilter[key][1].endOf('day').toISOString(),
              ].join();
              continue;
            }

            filter[key] = [baseFilter[key][0], baseFilter[key][1]].join();
            continue;
          }
        }
      }

      return filter;
    },

    transactionsChartData() {
      const lables = [], counts = [], amounts = [];

      const sortedStatistic = this.sortDates(this.fillMissingDates(this.transactionsStatistic, 'transaction', this.filter.transaction.created_time[0], this.filter.transaction.created_time[1]));

      for (let i=0; i<sortedStatistic.length; i++) {
        const {date, count, amount} = sortedStatistic[i];

        lables.push(date.day ? [date.day, date.month].join('/') : [date.month, date.year].join('/'));
        counts.push(count);
        amounts.push(amount);
      }

      return {
        labels: lables,
        datasets: [ 
          { label: 'Count', data: counts, backgroundColor: '#2871e9' },
          { label: 'Amount (USD)', data: amounts, backgroundColor: '#18d7ff' },
        ]
      }
    },

    accountsChartData() {
      const lables = [], counts = [];

      const sortedStatistic = this.sortDates(this.fillMissingDates(this.accountsStatistic, 'account', this.filter.account.created_time[0], this.filter.account.created_time[1]));

      for (let i=0; i<sortedStatistic.length; i++) {
        const {date, count} = sortedStatistic[i];

        lables.push(date.day ? [date.day, date.month].join('/') : [date.month, date.year].join('/'));
        counts.push(count);
      }

      return {
        labels: lables,
        datasets: [ 
          { label: 'Count', data: counts, backgroundColor: '#2871e9' }
        ]
      }
    },

    captchaSolvedChartData() {
      const lables = [], images = [], funcaptchas = [];

      const sortedStatistic = this.sortDates(this.fillMissingDates(this.captchaSolvedStatistic, 'captchaSolved', this.filter.captchaSolved.created_time[0], this.filter.captchaSolved.created_time[1]));

      for (let i=0; i<sortedStatistic.length; i++) {
        const {date, image, funcaptcha} = sortedStatistic[i];

        lables.push(date.day ? [date.day, date.month].join('/') : [date.month, date.year].join('/'));
        images.push(image);
        funcaptchas.push(funcaptcha);
      }

      return {
        labels: lables,
        datasets: [ 
          { label: 'Image', data: images, backgroundColor: '#2871e9' },
          { label: 'Funcaptcha', data: funcaptchas, backgroundColor: '#18d7ff' },
        ]
      }
    }
  },
  methods: {
    ...mapActions('statistic', {
      getTransactionsStatistic: "getTransactionsStatistic",
      getAccountsStatistic: "getAccountsStatistic",
      getCaptchaSolvedStatistic: "getCaptchaSolvedStatistic"
    }),

    fillMissingDates(data, type, minDate, maxDate) {
      const filledData = [];

      if (
        (data instanceof Array && data.some(item => item.date.day !== undefined))
        || (this.filter[type] && this.filter[type]?.view_by == 'day')
      ) {
        // Process dates with days
        let currentDate = moment(minDate);

        while (currentDate.isSameOrBefore(maxDate, 'day')) {
          const currentDay = currentDate.date();
          const currentMonth = currentDate.month() + 1; // months are 0-based in moment.js
          const currentYear = currentDate.year();

          const existingData = data.find(item => 
            item.date.year === currentYear &&
            item.date.month === currentMonth &&
            (item.date.day === currentDay || item.date.day === undefined)
          );

          if (existingData) {
            filledData.push(existingData);
          } else {
            if (type == 'account') {
              filledData.push({
                date: {
                  day: currentDay,
                  month: currentMonth,
                  year: currentYear
                },
                count: 0
              })
            }

            if (type == 'transaction') {
              filledData.push({
                date: {
                  day: currentDay,
                  month: currentMonth,
                  year: currentYear
                },
                count: 0,
                amount: 0
              });
            }
            
            if (type == 'captchaSolved') {
              filledData.push({
                date: {
                  day: currentDay,
                  month: currentMonth,
                  year: currentYear
                },
                funcaptcha: 0,
                image: 0
              });
            }
          }

          currentDate.add(1, 'day');
        }
      } else {
        // Process dates without days (months only)
        let currentDate = moment(minDate).startOf('month');

        while (currentDate.isSameOrBefore(maxDate, 'month')) {
          const currentMonth = currentDate.month() + 1; // months are 0-based in moment.js
          const currentYear = currentDate.year();

          const existingData = data.find(item => 
            item.date.year === currentYear &&
            item.date.month === currentMonth
          );

          if (existingData) {
            filledData.push(existingData);
          } else {
            if (type == 'account') {
              filledData.push({
                date: {
                  month: currentMonth,
                  year: currentYear
                },
                count: 0
              })
            }

            if (type == 'transaction') {
              filledData.push({
                date: {
                  month: currentMonth,
                  year: currentYear
                },
                count: 0,
                amount: 0
              });
            }
            
            if (type == 'captchaSolved') {
              filledData.push({
                date: {
                  month: currentMonth,
                  year: currentYear
                },
                funcaptcha: 0,
                image: 0
              });
            }
          }

          currentDate.add(1, 'month');
        }
      }

      return filledData;
    },

    sortDates(arr) {
      if (!(arr instanceof Array)) {
        return [];
      }

      return arr.sort((a, b) => {
        const dateA = a.date;
        const dateB = b.date;

        if (dateA.year !== dateB.year) {
          return dateA.year - dateB.year;
        }

        if (dateA.month !== dateB.month) {
          return dateA.month - dateB.month;
        }

        const dayA = dateA.day !== undefined ? dateA.day : 0;
        const dayB = dateB.day !== undefined ? dateB.day : 0;

        return dayA - dayB;
      });
    },

    async loadTransactionStatistic() {
      this.loading.transaction = true;
      try {
        await this.getTransactionsStatistic(this.transactionStatisticFilter);
      }
      catch(error) {
        const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

        this.$notification.error({message});

        if (message.includes('Unauthorized')) {
          this.$router.push({name: 'LoginPage'});
        }
      }
      this.loading.transaction = false;
    },

    async loadAccountStatistic() {
      this.loading.account = true;
      try {
        await this.getAccountsStatistic(this.accountStatisticFilter);
      }
      catch(error) {
        const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

        this.$notification.error({message});

        if (message.includes('Unauthorized')) {
          this.$router.push({name: 'LoginPage'});
        }
      }
      this.loading.account = false;
    },

    async loadCaptchaSolvedStatistic() {
      this.loading.captchaSolved = true;
      try {
        await this.getCaptchaSolvedStatistic(this.captchaSolvedStatisticFilter);
      }
      catch(error) {
        const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

        this.$notification.error({message});

        if (message.includes('Unauthorized')) {
          this.$router.push({name: 'LoginPage'});
        }
      }
      this.loading.captchaSolved = false;
    },
    
    async loadStatistic() {
      await Promise.all([
        this.loadTransactionStatistic(),
        this.loadAccountStatistic(),
        this.loadCaptchaSolvedStatistic()
      ]);
    }
  }
}
</script>