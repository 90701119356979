import * as getters from "./getters";
import * as actions from "./actions";
import { SET_PRICES } from "./types";

const state = {
  prices: {}
};

const mutations = {
  [SET_PRICES](state, prices) {
    state.prices = prices;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};