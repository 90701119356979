import { SET_SERVICE_URI } from "./types";

const SERVICE_URI_KEY = 'service_uri';

const state = {
  serviceUri: localStorage.getItem(SERVICE_URI_KEY) || '',
};

const mutations = {
  [SET_SERVICE_URI](state, serviceUri) {
    state.serviceUri = serviceUri;
    localStorage.setItem(SERVICE_URI_KEY, serviceUri);
  }
};

import * as getters from "./getters";
import * as actions from "./actions";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};