/* eslint-disable no-unused-vars */
import PromotionService from '@/services/promotion';
import { SET_DISCOUNTS, SET_PAGINATION, CREATE_DISCOUNT, UPDATE_DISCOUNT, DEL_DISCOUNT } from './types';

export async function getDiscounts({commit}, params) {
  const {data} = await PromotionService.getDisountCodes(params);
  commit(SET_DISCOUNTS, data.data.discounts);
  commit(SET_PAGINATION, data.data.pagination);
  return data.data;
}

export async function updateDiscountCode({commit}, {id, params}) {
  const {data} = await PromotionService.updateDiscountCode(id, params);
  commit(UPDATE_DISCOUNT, data.data.discount);
  return data.data;
}

export async function createDiscountCode({commit}, params) {
  const {data} = await PromotionService.createDiscountCode(params);
  commit(CREATE_DISCOUNT, data.data.discount);
  return data.data;
}

export async function deleteDiscounts({commit}, params) {
  const {data} = await PromotionService.deleteDiscountCodes(params);

  for (let i=0; i<params.discount_ids.length; i++) {
    commit(DEL_DISCOUNT, params.discount_ids[i]);
  }

  return data.data;
}

export async function appLyDiscount({commit}, params) {
  const {data} = await PromotionService.applyDiscountCode(params);
  return data.data;
}
