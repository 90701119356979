<template>
  <div>
    <a-row :gutter="[16,16]">
      <a-col :span="6">
        <a-input addon-before="Encrypted Time" v-model="encryptedTime"></a-input>
      </a-col>
      <a-col :span="18">
        <a-input addon-before="UserAgent" v-model="userAgent"></a-input>
      </a-col>
      <a-col :span="24">
        <a-button type="primary" @click="onClickDecrypt">Decrypt</a-button>
      </a-col>
      <a-col :span="8">
        <a-textarea placeholder="Input BDA here" :rows="25" v-model="bda" :autoSize="{minRows: 25, maxRows: 25}"/>
      </a-col>
      <a-col :span="16">
        <div ref="decryptedResultEditor" style="height: 534px"></div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import DevtoolService from '@/services/devtool';

export default {
  data() {
    return {
      encryptedTime: undefined,
      userAgent: undefined,
      bda: undefined,
      editor: null,
    }
  },
  mounted() {
    this.encryptedTime = this.generateCurrentTime();
    this.userAgent = navigator.userAgent;
    this.loadMonacoEditor();
  },
  methods: {
    generateCurrentTime() {
      const time = new Date().getTime() / 1000;
      return Math.round(time - (time % 21600));
    },

    loadMonacoEditor() {
      window.require.config({ paths: { vs: "https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.51.0/min/vs" } });
      window.require(["vs/editor/editor.main"], () => {
        // eslint-disable-next-line no-undef
        this.editor = monaco.editor.create(this.$refs.decryptedResultEditor, {
          language: 'json',
          theme: 'vs-dark',
          automaticLayout: true,
          minimap: { enabled: false },
        });
      });
    },

    async onClickDecrypt() {
      try {
        const {data} = await DevtoolService.decryptBda({
          bda: this.bda,
          user_agent: this.userAgent,
          timestamp: this.encryptedTime
        });
        this.$notification.success({message: data.message});
        this.editor.setValue(JSON.stringify(data.data.fingerprint));
        this.editor.getAction('editor.action.formatDocument').run();
      }
      catch(error) {
        const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

        this.$notification.error({message});

        if (message.includes('Unauthorized')) {
          this.$router.push({name: 'LoginPage'});
        }
      }
    }
  }
}
</script>
