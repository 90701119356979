<template>
  <div>
    <h1 style="font-size: 1.5em"><a-icon type="user" /> Accounts Management</h1>
    <br>
    <div id="filter">
      <a-input class="mr-10" v-model="filter.id" placeholder="Account ID" style="width: 300px">
        <span slot="addonBefore">Account ID</span>
      </a-input>
      <a-input class="mr-10" v-model="filter.email" placeholder="Email" style="width: 300px">
        <span slot="addonBefore">Email</span>
      </a-input>
      <a-input class="mr-10" v-model="filter.deposit_code" placeholder="Email" style="width: 300px">
        <span slot="addonBefore">Deposit Code</span>
      </a-input>
      <a-input-group class="mr-10" compact style="display: inline-block; width: 260px; line-height: 32px">
        <a-input v-model="filter.balance[0]" addonBefore="Balance" style=" width: 59.5%; text-align: center" placeholder="Min" />
        <a-input
          style=" width: 28px; border-left: 0; pointer-events: none; background: #fff"
          placeholder="-"
          disabled
        />
        <a-input v-model="filter.balance[1]" style="width: 30.5%; text-align: center; border-left: 0" placeholder="Max" />
      </a-input-group>
      <a-input-group class="mr-10" compact style="display: inline-block; width: 350px; line-height: 32px">
        <a-range-picker v-model="filter.created_at" />
      </a-input-group>
      <a-button type="primary" @click="loadAccounts"><a-icon type="filter" /> Filter</a-button>
    </div>
    <br v-if="tableSelectedRowKeys.length > 0">
    <div v-if="tableSelectedRowKeys.length > 0" id="actions">
      <a-button class="mr-10" type="primary" style="color: #52c41a; background-color: #f6ffed; border-color: #b7eb8f" @click="visible.plusBalanceModal = true"><a-icon type="plus-circle"></a-icon>Plus Balance</a-button>
      <a-button type="primary" style="color: #f5222d; background-color: #fff1f0; border-color: #ffa39e" @click="visible.minusBalanceModal = true"><a-icon type="minus-circle"></a-icon>Minus Balance</a-button>
    </div>
    <br v-if="tableSelectedRowKeys.length > 0">
    <a-table 
      :data-source="tableData" 
      :columns="columns" 
      :pagination="tablePagination" 
      :rowSelection="{
        selectedRowKeys: tableSelectedRowKeys,
        onChange: tableSelectedChange
      }"
      :loading="loading.loadAccounts"
      :scroll="{x: 1000, y: tableHeight}"
    >
      <a slot="email" slot-scope="email" :href="`mailto:${email}`">{{email}}</a>
      <span slot="balance" slot-scope="balance" :style="{color: 'green'}">
        ${{ parseFloat(balance.toFixed(5)) }}
      </span>
      <span slot="_id" class="ellipsis" slot-scope="_id"><a-button @click="copyValue(_id)" type="link" size="small" icon="copy" />{{_id}}</span>
    </a-table>
    <a-modal v-model="visible.plusBalanceModal" title="Plus Balance" @ok="handleCreateCreditTransaction">
      <p><i>You will plus balance of <b>{{tableSelectedRowKeys.length}}</b> accounts</i></p>
      <a-form>
        <a-form-item>
          <a-input 
            style="width: 100%" 
            placeholder="Enter amount"
            v-model="createTransactionForm.amount"
          >
            <span slot="addonBefore">Amount</span>
          </a-input>
        </a-form-item>
        <a-form-item>
          <span slot="label">Payment Gateway <i>(select to generate statistics)</i></span>
          <a-select v-model="createTransactionForm.gateway" placeholder="Gateway" allowClear>
            <a-select-option value="acb">
              <a-tag style="background: rgba(200, 255, 249, 0.8); color: rgb(31 65 155)">ACB</a-tag>
            </a-select-option>
            <a-select-option value="binance">
              <a-tag style="background: rgb(24, 26, 32); color: rgb(240 185 11)">Binance</a-tag>
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Message">
          <a-textarea v-model="createTransactionForm.message" placeholder="Enter transaction message"></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal v-model="visible.minusBalanceModal" title="Minus Balance" @ok="handleCreateDebitTransaction">
      <p><i>You will minus balance of <b>{{tableSelectedRowKeys.length}}</b> accounts</i></p>
      <a-form>
        <a-form-item>
          <a-input 
            style="width: 100%" 
            placeholder="Enter amount"
            v-model="createTransactionForm.amount"
          >
            <span slot="addonBefore">Amount</span>
          </a-input>
        </a-form-item>
        <a-form-item label="Message">
          <a-textarea v-model="createTransactionForm.message" placeholder="Enter transaction message"></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<style scoped>
#filter {
  margin-bottom: 10px;
}

#filter .mr-10 {
  margin-right: 10px;
  margin-bottom: 10px;
}

#actions .mr-10 {
  margin-right: 10px;
  margin-bottom: 10px;
}

.ellipsis {
  display: inline-block;
  width: 10ch; /* Limit to 5 characters */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom; /* Align ellipsis properly */
}

/* Mobile Styles */
@media (max-width: 768px) {
  #filter .mr-10 {
    width: 100%!important;
  }
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex';

const columns = [
  {
    title: 'No.',
    dataIndex: 'no',
    key: 'no',
    width: 80,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    scopedSlots: { customRender: 'email' }
  },
  {
    title: 'Deposit Code',
    dataIndex: 'deposit_code',
    key: 'deposit_code',
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    width: 130,
    scopedSlots: { customRender: 'balance' }
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: 'ID',
    dataIndex: '_id',
    key: '_id',
    scopedSlots: { customRender: '_id' }
  },
];

const pageSize = 10;

export default {
  data() {
    return {
      filter: {
        page: 1,
        page_size: pageSize,
        balance: {
          0: undefined,
          1: undefined
        }
      },
      columns,
      loading: {
        loadAccounts: false
      },
      tableSelectedRowKeys: [],
      tablePagination: {
        total: 0,
        pageSize: pageSize,
        current: 1,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
        onChange: this.handleTableChange,
        onShowSizeChange: this.handlePageSizeChange,
        showTotal: (total, range) => {
          return `${range[0]} - ${range[1]} of ${total}`;
        }
      },
      visible: {
        plusBalanceModal: false,
        minusBalanceModal: false,
      },
      createTransactionForm: {
        amount: undefined,
        message: undefined,
        gateway: undefined
      }
    }
  },
  async mounted() {
    this.loadAccounts();
  },
  computed: {
    ...mapGetters('account', {
      accounts: 'getAccounts',
      pagination: 'getPagination'
    }),

    tableHeight() {
      return Math.round(innerHeight / 2);
    },

    tableData() {
      if (!this.accounts) {
        return [];
      }

      if (this.accounts instanceof Array) {
        return this.accounts.map((account, index) => {
          account.key = account._id;
          account.no = index + 1;
          account.createdAt = new Date(account.createdAt).toLocaleString();

          return account;
        });
      }

      return [];
    },

    optimizedFilter() {
      const filter = {};

      for (let key in this.filter) {
        if (this.filter[key]) {
          if (typeof this.filter[key] == 'string' || typeof this.filter[key] == 'number') {
            filter[key] = this.filter[key];
            continue;
          }

          if (typeof this.filter[key] == 'object' && this.filter[key][0] && this.filter[key][1]) {
            if (key == 'created_at') {
              filter[key] = [
                this.filter[key][0].startOf('day').toISOString(),
                this.filter[key][1].endOf('day').toISOString(),
              ].join();
              continue;
            }

            filter[key] = [this.filter[key][0], this.filter[key][1]].join();
            continue;
          }
        }
      }

      return filter;
    }
  },
  methods: {
    ...mapActions('account', {
      getAccounts: 'getAccounts'
    }),

    ...mapActions('transaction', {
      createTransactions: 'createTransactions'
    }),

    async copyValue(value) {
      await navigator.clipboard.writeText(value);
      this.$message.success("Copied");
    },

    async loadAccounts() {
      this.loading.loadAccounts = true;
      
      try {
        await this.getAccounts(this.optimizedFilter);
        this.tablePagination.total = this.pagination.total;
        this.tablePagination.pageSize = this.pagination.pageSize;
      }
      catch(error) {
        const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

        this.$notification.error({message});

        if (message.includes('Unauthorized')) {
          this.$router.push({name: 'LoginPage'});
        }
      }

      this.loading.loadAccounts = false;
    },

    handlePageSizeChange(page, pageSize) {
      this.filter.page = page;
      this.filter.page_size = pageSize;
      this.loadAccounts();
    },

    async handleTableChange(page) {
      this.filter.page = page;
      await this.loadAccounts();
      this.tablePagination.current = page;
    },

    tableSelectedChange(selectedRowKeys) {
      this.tableSelectedRowKeys = selectedRowKeys;
    },

    async handleCreateCreditTransaction() {
      try {
        await this.createTransactions({
          account_ids: this.tableSelectedRowKeys,
          type: 'credit',
          ...this.createTransactionForm
        });
        this.visible.plusBalanceModal = false;
        this.$notification.success({message: 'Create transactions success'});
        this.loadAccounts();
      }
      catch(error) {
        const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

        this.$notification.error({message});

        if (message.includes('Unauthorized')) {
          this.$router.push({name: 'LoginPage'});
        }
      }
    },

    async handleCreateDebitTransaction() {
      try {
        await this.createTransactions({
          account_ids: this.tableSelectedRowKeys,
          type: 'debit',
          ...this.createTransactionForm
        });
        this.visible.minusBalanceModal = false;
        this.$notification.success({message: 'Create transactions success'});
        this.loadAccounts();
      }
      catch(error) {
        const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

        this.$notification.error({message});

        if (message.includes('Unauthorized')) {
          this.$router.push({name: 'LoginPage'});
        }
      }
    },
  },
}
</script>