<template>
  <div>
    <h1 style="font-size: 1.5em"><font-awesome-icon class="mr-10" :icon="['fas', 'cube']" /> List of Packages</h1>
    <br>
    <div id="filter">
      <a-select v-model="filter.type" placeholder="Type" class="mr-10" default-value="" allowClear style="width: 200px">
        <a-select-option value="" disabled>
          Type
        </a-select-option>
        <a-select-option value="funcaptcha">
          <a-avatar src="/images/funcaptcha.png" style="margin-right: 5px"></a-avatar>Funcaptcha
        </a-select-option>
        <a-select-option value="image">
          <a-avatar src="/images/itt.png" style="margin-right: 5px"></a-avatar>Image To Text
        </a-select-option>
      </a-select>
      <a-select v-model="filter.period" placeholder="Period" class="mr-10" default-value="" allowClear style="width: 110px">
        <a-select-option value="" disabled>
          Period
        </a-select-option>
        <a-select-option value="1">
          <a-tag color="blue">1 days</a-tag>
        </a-select-option>
        <a-select-option value="7">
          <a-tag color="purple">7 days</a-tag>
        </a-select-option>
        <a-select-option value="30">
          <a-tag color="orange">30 days</a-tag>
        </a-select-option>
      </a-select>
      <a-select v-model="filter.unlimited" placeholder="Pay per" class="mr-10" default-value="" allowClear style="width: 150px">
        <a-select-option value="" disabled>
          Pay per
        </a-select-option>
        <a-select-option value="false">
          <a-tag color="blue">Pay per use</a-tag>
        </a-select-option>
        <a-select-option value="true">
          <a-tag color="orange">Pay per thread</a-tag>
        </a-select-option>
      </a-select>
      <a-input class="mr-10" v-model="filter.email" placeholder="Email" style="width: 300px">
        <span slot="addonBefore">Email</span>
      </a-input>
      <a-input class="mr-10" v-model="filter.account_id" placeholder="Account ID" style="width: 300px">
        <span slot="addonBefore">Account ID</span>
      </a-input>
      <a-input-group class="mr-10" compact style="display: inline-block; width: 260px; line-height: 32px">
        <a-input v-model="filter.balance[0]" addonBefore="Balance" style=" width: 58.5%; text-align: center" placeholder="Min" />
        <a-input
          style=" width: 28px; border-left: 0; pointer-events: none; background: #fff"
          placeholder="-"
          disabled
        />
        <a-input v-model="filter.balance[1]" style="width: 31.5%; text-align: center; border-left: 0" placeholder="Max" />
      </a-input-group>
      <a-input-group class="mr-10" compact style="display: inline-block; width: 260px; line-height: 32px">
        <a-input v-model="filter.quantity[0]" addonBefore="Quantity" style=" width: 60.5%; text-align: center" placeholder="Min" />
        <a-input
          style=" width: 28px; border-left: 0; pointer-events: none; background: #fff"
          placeholder="-"
          disabled
        />
        <a-input v-model="filter.quantity[1]" style="width: 29.5%; text-align: center; border-left: 0" placeholder="Max" />
      </a-input-group>
      <a-input-group class="mr-10" compact style="display: inline-block; width: 260px; line-height: 32px">
        <a-input v-model="filter.threads[0]" addonBefore="Threads" style=" width: 60.5%; text-align: center" placeholder="Min" />
        <a-input
          style=" width: 28px; border-left: 0; pointer-events: none; background: #fff"
          placeholder="-"
          disabled
        />
        <a-input v-model="filter.threads[1]" style="width: 29.5%; text-align: center; border-left: 0" placeholder="Max" />
      </a-input-group>
      <span>Expired at: </span>
      <a-input-group class="mr-10" compact style="display: inline-block; width: 350px; line-height: 32px">
        <a-range-picker v-model="filter.expired" />
      </a-input-group>
      <span>Created at: </span>
      <a-input-group class="mr-10" compact style="display: inline-block; width: 350px; line-height: 32px">
        <a-range-picker v-model="filter.created_at" />
      </a-input-group>
      <a-button type="primary" @click="loadPackages"><a-icon type="filter" /> Filter</a-button>
    </div>
    <br v-if="tableSelectedRowKeys.length > 0">
    <div v-if="tableSelectedRowKeys.length > 0" id="actions">
      <a-button @click="handleChangeAPIKeySelected" :loading="loading.changeAPIKeySelected" class="mr-10" type="primary" style="color: #1890ff; background-color: #e6f7ff; border-color: #91d5ff"><a-icon type="api"></a-icon>Change API-Key</a-button>
      <a-button @click="handleDeleteSelected" :loading="loading.deleteSelected" type="primary" class="mr-10" style="color: #f5222d; background-color: #fff1f0; border-color: #ffa39e"><a-icon type="delete"></a-icon>Delete</a-button>
      <a-button @click="showUpdatePeriodModal('increase')" :loading="loading.incPeriodSelected" class="mr-10" type="primary" style="color: rgb(71 172 7); background-color: #f1fff0; border-color: #81ed79"><a-icon type="plus-circle"></a-icon>Increase Period</a-button>
      <a-button @click="showUpdatePeriodModal('reduce')" :loading="loading.reducePeriodSelected" type="primary" style="color: rgb(242 44 123); background-color: #fff0fb; border-color: rgb(196 171 190)"><a-icon type="minus-circle"></a-icon>Reduce Period</a-button>
    </div>
    <br v-if="tableSelectedRowKeys.length > 0">
    <a-table 
      :data-source="tableData" 
      :columns="columns" 
      :pagination="tablePagination"
      :loading="loading.loadPackages"
      :rowSelection="{
        selectedRowKeys: tableSelectedRowKeys,
        onChange: tableSelectedChange
      }"
      :scroll="{x: 1400, y: tableHeight}"
    >
      <span slot="type" slot-scope="type">
        <a-avatar :src="type == 'funcaptcha' ? `/images/funcaptcha.png` : '/images/itt.png'" style="margin-right: 5px"></a-avatar>{{type == 'funcaptcha' ? 'Funcaptcha' : 'Image To Text'}}
      </span>
      <a-tag slot="period" slot-scope="period" :color="{1: 'blue', 7: 'purple', 30: 'orange'}[period]">{{period}} days</a-tag>
      <a-input-password slot="api_key" slot-scope="api_key" :value="api_key" :readOnly="true">
        <a-button @click="copyValue(api_key)" style="height: 25px; padding: 0; border: none" slot="addonAfter" type="secondary"><a-icon type="copy" /></a-button>
      </a-input-password>
      <span slot="account_id" class="ellipsis" slot-scope="account_id"><a-button @click="copyValue(account_id)" type="link" size="small" icon="copy" />{{account_id}}</span>
      <span slot="balance" slot-scope="balance, pkg">
        <span v-if="!pkg.unlimited"><b :style="{color: pkg.balance <= 0 ? 'red' : 'blue'}">{{pkg.balance}}</b>/{{pkg.quantity}}</span>
        <span v-if="pkg.unlimited" style="color: blue"><b>Infinity</b></span>
      </span>
      <span slot="expired" slot-scope="expired, pkg" :style="{color: pkg.is_expired ? 'red' : 'rgba(0, 0, 0, 0.65)'}">{{expired}}</span>
      <span slot="threads" slot-scope="threads">
        <a-badge v-if="threads >= 1" :count="threads" :overflow-count="10000" :number-style="{ backgroundColor: '#52c41a' }" />
        <span v-if="threads == -1" style="color: blue">Unlimited</span>
      </span>
    </a-table>

    <a-modal 
      v-model="visibleModal.updatePeriod" 
      :dialog-style="{ top: '45px' }"  
      :destroyOnClose="true" 
      :title="action_type == 'increase' ? 'Increase period' : 'Reduce period'" 
      :footer="null"
    >
      <update-period :action-type="action_type" :package-ids="tableSelectedRowKeys" :selected-length="tableSelectedRowKeys.length" @close="visibleModal.updatePeriod = false"></update-period>
    </a-modal>
  </div>
</template>

<style scoped>
#filter {
  margin-bottom: 10px;
}

#filter .mr-10 {
  margin-right: 10px;
  margin-bottom: 10px;
}

.ellipsis {
  display: inline-block;
  width: 10ch; /* Limit to 5 characters */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom; /* Align ellipsis properly */
}

/* Mobile Styles */
@media (max-width: 768px) {
  #filter .mr-10 {
    width: 100%!important;
  }
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import UpdatePeriod from './package/updatePeriodModal.vue';

const columns = [
  {
    title: 'No.',
    dataIndex: 'no',
    key: 'no',
    width: 80,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: 'Period',
    dataIndex: 'period',
    key: 'period',
    scopedSlots: { customRender: 'period' },
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    scopedSlots: { customRender: 'balance' },
  },
  {
    title: 'Threads',
    dataIndex: 'threads',
    key: 'threads',
    scopedSlots: { customRender: 'threads' },
  },
  {
    title: 'API-Key',
    dataIndex: 'api_key',
    key: 'api_key',
    scopedSlots: { customRender: 'api_key' },
  },
  {
    title: 'Expired at',
    dataIndex: 'expired',
    key: 'expired',
    scopedSlots: { customRender: 'expired' },
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: 'Account Id',
    dataIndex: 'account_id',
    key: 'account_id',
    scopedSlots: { customRender: 'account_id' }
  },
];

const pageSize = 10;

export default {
  components: {
    UpdatePeriod
  },

  data() {
    return {
      filter: {
        page: 1,
        page_size: pageSize,
        balance: {
          0: undefined,
          1: undefined
        },
        quantity: {
          0: undefined,
          1: undefined
        },
        threads: {
          0: undefined,
          1: undefined
        },
      },
      columns,
      loading: {
        loadPackages: false,
        deleteSelected: false,
        changeAPIKeySelected: false,
        incPeriodSelected: false,
        reducePeriodSelected: false
      },
      visibleModal: {
        updatePeriod: false
      },
      action_type: 'increase',
      tableSelectedRowKeys: [],
      tablePagination: {
        total: 0,
        pageSize: pageSize,
        current: 1,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
        onChange: this.handleTableChange,
        onShowSizeChange: this.handlePageSizeChange,
        showTotal: (total, range) => {
          return `${range[0]} - ${range[1]} of ${total}`;
        }
      },
    }
  },
  async mounted() {
    this.loadPackages();
  },
  computed: {
    ...mapGetters('packages', {
      packages: 'getPackages',
      pagination: 'getPagination'
    }),

    tableHeight() {
      return Math.round(innerHeight / 2);
    },

    tableData() {
      if (!this.packages) {
        return [];
      }

      if (this.packages instanceof Array) {
        return this.packages.map((pkg, index) => {
          pkg.key = pkg._id;
          pkg.no = index + 1;
          pkg.createdAt = new Date(pkg.createdAt).toLocaleString();
          pkg.expired = new Date(pkg.expired).toLocaleString();

          return pkg;
        });
      }

      return [];
    },

    optimizedFilter() {
      const filter = {};

      for (let key in this.filter) {
        if (this.filter[key]) {
          if (typeof this.filter[key] == 'string' || typeof this.filter[key] == 'number') {
            filter[key] = this.filter[key];
            continue;
          }

          if (typeof this.filter[key] == 'object' && this.filter[key][0] && this.filter[key][1]) {
            if (key == 'created_at' || key == 'expired') {
              filter[key] = [
                this.filter[key][0].startOf('day').toISOString(),
                this.filter[key][1].endOf('day').toISOString(),
              ].join();
              continue;
            }

            filter[key] = [this.filter[key][0], this.filter[key][1]].join();
            continue;
          }
        }
      }

      return filter;
    }
  },
  methods: {
    ...mapActions('packages', {
      getPackages: 'getPackages',
      deletePackagesByAdmin: 'deletePackagesByAdmin',
      changeAPIKeyByAdmin: 'changeAPIKeyByAdmin'
    }),

    async copyValue(value) {
      await navigator.clipboard.writeText(value);
      this.$message.success("Copied");
    },

    tableSelectedChange(selectedRowKeys) {
      this.tableSelectedRowKeys = selectedRowKeys;
    },

    async loadPackages() {
      this.loading.loadPackages = true;
      
      try {
        await this.getPackages(this.optimizedFilter);
        this.tablePagination.total = this.pagination.total;
        this.tablePagination.pageSize = this.pagination.pageSize;
      }
      catch(error) {
        const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

        this.$notification.error({message});

        if (message.includes('Unauthorized')) {
          this.$router.push({name: 'LoginPage'});
        }
      }

      this.loading.loadPackages = false;
    },

    handlePageSizeChange(page, pageSize) {
      this.filter.page = page;
      this.filter.page_size = pageSize;
      this.loadPackages();
    },

    async handleTableChange(page) {
      this.filter.page = page;
      await this.loadPackages();
      this.tablePagination.current = page;
    },

    handleDeleteSelected() {
      const deleteCount = this.tableSelectedRowKeys.length;

      this.$confirm({
        title: `Are you sure you want to delete these ${deleteCount} packages?`,
        onOk: async () => {
          this.loading.deleteSelected = true;
          try {
            await this.deletePackagesByAdmin({package_ids: this.tableSelectedRowKeys});
            this.tableSelectedRowKeys = [];
            this.$notification.success({message: `Deleted ${deleteCount} packages!`});
          }
          catch(error) {
            const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

            this.$notification.error({message});

            if (message.includes('Unauthorized')) {
              this.$router.push({name: 'LoginPage'});
            }
          }
          this.loading.deleteSelected = false;
        }
      });
    },

    handleChangeAPIKeySelected() {
      const changeCount = this.tableSelectedRowKeys.length;

      this.$confirm({
        title: `Are you sure you want to change the API key for these ${changeCount} packages?`,
        content: `This will disable the current API key for all ${changeCount} packages!`,
        onOk: async () => {
          this.loading.changeAPIKeySelected = true;
          try {
            await this.changeAPIKeyByAdmin({package_ids: this.tableSelectedRowKeys});
            this.$notification.success({message: `Changed API-Key of ${changeCount} packages!`});
          }
          catch(error) {
            const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

            this.$notification.error({message});

            if (message.includes('Unauthorized')) {
              this.$router.push({name: 'LoginPage'});
            }
          }
          this.loading.changeAPIKeySelected = false;
        }
      });
    },

    showUpdatePeriodModal(type) {
      if(type == 'increase') {
        this.action_type = 'increase';
        this.visibleModal.updatePeriod = true;
      }
      else {
        this.action_type = 'reduce';
        this.visibleModal.updatePeriod = true;
      }
    }
  },
}
</script>