<template>
  <div>
    <a-alert v-if="completed" :message="`Payment successful, you received $${usdAmount}!`" type="success" show-icon />
    <a-alert v-if="!completed" :message="`Waiting for a payment of ${amount}${currency}, after successful payment, you will receive $${usdAmount}...`" type="info" show-icon />
    <br>
    <a-progress
      v-if="!completed"
      :stroke-color="{
        from: '#108ee9',
        to: '#87d068',
      }"
      :percent="timeLeftPercent"
      status="active"
      :showInfo="false"
    />
    <span v-if="!completed">Expiration time: <b v-html="timeLeftHtml"></b></span>
    <br v-if="!completed"><br v-if="!completed">
    <a-descriptions layout="vertical" bordered>
      <a-descriptions-item label="Binance ID">
        <b>{{ id }}</b><a-button @click="copyValue(id)" type="link" size="small" icon="copy" />
      </a-descriptions-item>
      <a-descriptions-item label="Amount">
        <b>{{ amount }}</b><a-button @click="copyValue(amount)" type="link" size="small" icon="copy" />
      </a-descriptions-item>
      <a-descriptions-item label="Currency">
        <b>{{ currency }}</b>
      </a-descriptions-item>
    </a-descriptions>
    <br>
    <qr-code :width="512" :height="512" :text="qr"></qr-code>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import QrCode from '../../../utils/QrCode.vue';

export default {
  components: {
    QrCode
  },
  props: {
    id: {
      type: String,
      required: true
    },
    amount: {
      type: Number,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    qr: {
      type: String,
      required: true
    },
    createdAt: {
      type: Number,
      required: true
    },
    expiredAt: {
      type: Number,
      required: true
    },
    usdAmount: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      imageLoaded: false,
      totalTime: this.expiredAt - this.createdAt,
      timeLeft: this.expiredAt - Date.now(),
      timeLeftPercent: (this.timeLeft / this.totalTime) * 100,
      timeLeftHtml: '',
      completed: false
    }
  },
  mounted() {
    this.caculateTimeLeft();

    this.loopProgress = setInterval(() => {
      if (this.timeLeft <= 0) {
        clearInterval(this.loopProgress);
      }

      this.caculateTimeLeft();
    }, 300);

    this.loopCheckComplete = setInterval(async () => {
      const completed = await this.isCompletedTransaction();

      if (completed) {
        this.completed = true;
        clearInterval(this.loopCheckComplete);
      }
    }, 10 * 1000);
  },
  destroyed() {
    this.clear();
  },
  methods: {
    ...mapActions('deposit', {
      getBinanceTransaction: "getBinanceTransaction"
    }),

    caculateTimeLeft() {
      this.timeLeft = this.expiredAt - Date.now();
      this.timeLeftPercent = (this.timeLeft / this.totalTime) * 100;

      if (this.timeLeft > 0) {
        const date = new Date(null);
        date.setSeconds(parseInt(this.timeLeft / 1000));
        this.timeLeftHtml = `<span>${date.toISOString().slice(11, 19)}</span>`;
      } else {
        this.timeLeftHtml = '<span style="color: red;">00:00:00</span>';
      }
    },

    async copyValue(value) {
      await navigator.clipboard.writeText(value);
      this.$message.success("Copied");
    },

    async isCompletedTransaction() {
      const data = await this.getBinanceTransaction();
      return data.completed;
    },

    clear() {
      clearInterval(this.loopProgress);
      clearInterval(this.loopCheckComplete);
    }
  }
}
</script>