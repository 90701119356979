<template>
  <a-layout id="components-layout" class="layout">
    <Navigation />
    <Content />
    <Footer />
    <a-back-top :visibility-height="100">
      <div class="back-top-button">
        <a-icon type="arrow-up" />
      </div>
    </a-back-top>
  </a-layout>
</template>

<script>
import { mapActions } from "vuex";

import Navigation from '@/components/layouts/NavigationLayout.vue';
import Footer from '@/components/layouts/FooterLayout.vue';
import Content from '@/components/layouts/ContentLayout.vue';

export default {
  data() {
    return {
      publicPage: ['LoginPage', 'HomePage', 'RegisterPage', 'TermPage', 'RefundPolicyPage', 'PrivacyPolicyPage', 'ForgotPage', 'FreeTrialPage']
    }
  },
  name: 'CommonLayout',
  components: {
    Navigation,
    Footer,
    Content
  },
  mounted() {
    this.validateSession();
    this.showPopup();
  },
  computed: {
    route() {
      return this.$route.name;
    }
  },
  methods: {
    ...mapActions('auth', {
      getProfile: "getProfile",
      removeSession: "removeSession"
    }),

    isOriginWithoutPath(str) {
      try {
        const url = new URL(str);
        return url.pathname === '/' && !str.endsWith('/');
      } catch (error) {
        return false;
      }
    },

    inPublicPage() {
      return this.publicPage.includes(this.$route.name);
    },

    async callApi(func, onError) {
      try {
        await func();
      }
      catch(error) {
        if (onError) {
          onError();
        }

        const message = error?.response?.data?.error?.message || error?.response?.data || error?.message;

        this.$notification.error({message});

        if (message.includes('Unauthorized')) {
          this.$router.push({name: 'LoginPage'});
        }
      }
    },

    async validateSession() {
      if (!this.inPublicPage()) {
        try {
          await this.getProfile();
        }
        catch(err) {
          if (this.$route.name != 'LoginPage') {
            this.$notification.error({message: err?.response?.data || err.message});
          }

          if (err?.code == 'ERR_NETWORK') {
            this.$router.push({name: 'ServiceSetting'});
          } else {
            this.$router.push({name: 'LoginPage'});
          }
        } 
      }
    },

    showPopup() {
      const key = `popup-subcribe-telegram`;
      const subcribeUrl = 'https://t.me/mrccaptcha_channel';
      const popupCached = localStorage.getItem(key);
      
      if(popupCached && popupCached==0) {
        return;
      }

      this.$notification.open({
        message: 'Follow Telegram channel!',
        description: `Follow us to never miss out on "important announcements" and "exciting promotions"`,
        icon: <a-icon type="notification" />,
        duration: 0,
        placement: 'bottomRight',
        btn: h => {
          return h(
            'div',
            [
              h(
                'a-button',
                {
                  props: {
                    type: 'dashed'
                  },
                  style: {
                    marginRight: '10px'
                  },
                  on: {
                    click: () => { 
                      localStorage.setItem(key, 0);
                      this.$notification.close(key);
                    }
                  }
                },
                `Don't show again`
              ),

              h(
                'a-button',
                {
                  props: {
                    type: 'primary'
                  },
                  on: {
                    click: () => { 
                      open(subcribeUrl, "_blank");
                      localStorage.setItem(key, 0);
                      this.$notification.close(key);
                    }
                  }
                },
                `Follow`
              ),
            ]
          );
        },
        key,
      });
    }
  },
  watch: {
    route() {
      this.validateSession();
    }
  }
};
</script>

<style>
html {
  scroll-behavior: smooth;
}

@media only screen and (min-width: 1024px) {
  body {
    font-family: cursive!important
  }
}

#components-layout {
  min-height: 100%;
}

*::-webkit-scrollbar {
  width: 0.7em;
}

*::-webkit-scrollbar:horizontal {
  height: 0.7em;
}
 
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(252, 246, 246, 0.925);
}
 
*::-webkit-scrollbar-thumb {
  background-color: #bfbfbfcf;
}

.ant-back-top-content {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.back-top-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(24, 144, 255, 0.5);
  color: #fff;
  font-size: 24px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s, transform 0.3s;
}

.back-top-button:hover {
  opacity: 0.8;
  transform: scale(1.2);
}

.back-top-button:active {
  opacity: 1;
  transform: scale(0.8);
}

#components-layout > ul > li:nth-child(3) {
  float: right;
  font-size: 24px;
}

.form-lable-left .ant-form-item-label {
  text-align: left;
}

.hide-ant-result-icon .ant-result-icon {
  display: none;
}

.hide-ant-result-icon .ant-result-content {
  margin-top: 0;
}

.mr-10 {
  margin-right: 10px;
}

.pd-lr-zero > .ant-card-body {
  padding-left: 0;
  padding-right: 0;
}

.a-from-item-label-left > div {
  text-align: left;
}

</style>