import { render, staticRenderFns } from "./NavigationLayout.vue?vue&type=template&id=53f287d3&scoped=true&"
import script from "./NavigationLayout.vue?vue&type=script&lang=js&"
export * from "./NavigationLayout.vue?vue&type=script&lang=js&"
import style0 from "./NavigationLayout.vue?vue&type=style&index=0&id=53f287d3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53f287d3",
  null
  
)

export default component.exports