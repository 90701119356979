<template>
  <div>
    <a-button class="crypto-btn" @click="depositType = DepositTypes.BINANCE" style="background-color: #181A20;">
      <img src="/images/binance.svg">
    </a-button>
    <br><br>
    <a-form class="submit-form" v-if="depositType == DepositTypes.BINANCE" :form="form" @submit="handleSubmit">
      <a-form-item v-if="binanceDepositAmountUsd">
        <a-alert :message="`You will receive ~ $${binanceDepositAmountUsd}`" type="info" show-icon />
      </a-form-item>
      <a-form-item>
        <a-input
          v-decorator="[
            'amount',
            { rules: [
              { required: true, message: 'Please enter amount!' },
              { pattern: /^\d+([,.]\d+)?$/, message: 'Amount must be a number' }
            ] },
          ]"
          type="text"
          placeholder="Amount"
          :addon-before="`[Binance]`"
          @change="caculateAmount"
        >
          <a-select v-model="binanceCurrency" slot="addonAfter" :default-value="binanceCurrency" style="width: 80px">
            <a-select-option value="USDT">
              USDT
            </a-select-option>
            <a-select-option value="BTC">
              BTC
            </a-select-option>
            <a-select-option value="ETH">
              ETH
            </a-select-option>
            <a-select-option value="BNB">
              BNB
            </a-select-option>
            <a-select-option value="SOL">
              SOL
            </a-select-option>
            <a-select-option value="USDC">
              USDC
            </a-select-option>
          </a-select>
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          v-decorator="[
            'username',
            { rules: [
              { required: true, message: 'Please enter your Binance username!' }
            ] },
          ]"
          type="text"
          placeholder="Username, ex: User-123456"
          :addon-before="`[Binance]`"
        >
          <a-tooltip slot="suffix">
            <a-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
            <span slot="title">Input your Binance username, see the guide to obtain your Binance username <a href="https://docx.mrccaptcha.com/instructions-for-getting-binance-username" target="_blank">here</a></span>
          </a-tooltip>
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-alert message="Please note, enter the correct amount, currency, and your Binance account username. Otherwise, the transaction will not be processed!" type="warning" show-icon />
      </a-form-item>
      <google-recaptcha-v2 ref="grecaptcha-component"></google-recaptcha-v2>
      <a-form-item>
        <a-button :loading="loading.createBinanceTransaction" type="primary" html-type="submit" class="submit-form-button">
          <a-icon type="qrcode" /> Create transaction
        </a-button>
      </a-form-item>
      <a-modal v-model="binancePayInvoiceVisible" :destroyOnClose="true" v-if="currentBinanceTransaction" title="Scan QR with your Binance app" @ok="onCloseBinancePayInvoiceModal" @cancel="onCloseBinancePayInvoiceModal">
        <binance-qr
          ref="binance-pay-invoice"
          :createdAt="currentBinanceTransaction.createdAt" 
          :expiredAt="currentBinanceTransaction.expiredAt" 
          :usd-amount="currentBinanceTransaction.amount"
          :id="currentBinanceDepositInfo.id" 
          :amount="currentBinanceDepositInfo.amount" 
          :qr="currentBinanceDepositInfo.qr"
          :currency="binanceCurrency"
          :key="keys.binancePayInvoice"
        ></binance-qr>
      </a-modal>
    </a-form>
  </div>
</template>

<style>
.crypto-btn {
  text-align: center;
}

.crypto-btn img {
  max-width: 100%;
  height: 24px;
  display: block;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';
import GoogleRecaptchaV2 from '@/components/utils/GoogleRecaptchaV2.vue';
import BinanceQr from './BinanceQr.vue';

const DepositTypes = {
  BINANCE: 'BINANCE'
};

export default {
  components: {
    GoogleRecaptchaV2,
    BinanceQr
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'crypto-deposit' });
  },
  data() {
    return {
      DepositTypes,
      depositType: undefined,
      binancePayInvoiceVisible: false,
      binanceDepositAmount: undefined,
      binanceCurrency: 'USDT',
      currentBinanceTransaction: null,
      loading: {
        createBinanceTransaction: false
      },
      keys: {
        binancePayInvoice: 0
      }
    }
  },
  computed: {
    ...mapGetters('deposit', {
      depositInfo: "getDepositInfo"
    }),

    exchangeRates() {
      return this.depositInfo.exchange_rates;
    },

    binanceDepositAmountUsd() {
      return parseFloat((this.exchangeRates[this.binanceCurrency] * this.binanceDepositAmount).toFixed(5));
    },

    currentBinanceDepositInfo() {
      const binanceInfo = this.depositInfo.methods.binance;

      return {
        amount: this.binanceDepositAmount,
        id: binanceInfo.id,
        qr: binanceInfo.qr,
        minpay: binanceInfo.minpay
      }
    },

    grecaptchaComponent() {
      return this.$refs['grecaptcha-component'];
    }
  },
  mounted() {
    this.getDepositInfo();
  },
  methods: {
    ...mapActions('deposit', {
      getDepositInfo: "getInfo",
      createBinanceTransaction: "createBinanceTransaction"
    }),

    caculateAmount() {
      setTimeout(() => {
        this.binanceDepositAmount = Number(this.form.getFieldValue('amount').replace(/,/g, '.'));
      }, 50);
    },

    onCloseBinancePayInvoiceModal() {
      this.binancePayInvoiceVisible = false;
      this.$refs['binance-pay-invoice'].clear();
    },

    handleSubmit(e) {
      e.preventDefault();

      if (!this.grecaptchaComponent.rendered) {
        this.grecaptchaComponent.render();
        return;
      }

      this.form.validateFields(async (err, values) => {
        if (err) {
          return;
        }

        if (!this.grecaptchaComponent.getResponse()) {
          this.$notification.error({message: 'Please verify the captcha'});
          return;
        }

        this.binanceDepositAmount = Number(values.amount.replace(/,/g, '.'));

        if (this.depositType == DepositTypes.BINANCE) {
          this.loading.createBinanceTransaction = true;

          try {
            const transaction = await this.createBinanceTransaction({
              username: values.username,
              amount: this.binanceDepositAmount,
              currency: this.binanceCurrency,
              recaptcha: this.grecaptchaComponent.getResponse()
            });

            this.keys.binancePayInvoice ++;

            this.currentBinanceTransaction = transaction;
            this.loading.createBinanceTransaction = false;
            this.binancePayInvoiceVisible = true;
            this.grecaptchaComponent.reset();
          }
          catch(error) {
            this.loading.createBinanceTransaction = false;
            const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

            this.$notification.error({message});

            if (message.includes('Unauthorized')) {
              this.$router.push({name: 'LoginPage'});
            }
            
            this.grecaptchaComponent.reset();
          }
        }
      });
    },
  },
}
</script>
