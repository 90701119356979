import {SET_TRANSACTION_STATISTIC, SET_ACCOUNT_STATISTIC, SET_CAPTCHA_SOLVED_STATISTIC} from './types';

/* eslint-disable no-unused-vars */
import StatisticService from '@/services/statistic';

export async function getTransactionsStatistic({commit}, params) {
  const {data} = await StatisticService.getTransactionsStatistic(params);
  commit(SET_TRANSACTION_STATISTIC, data.data.statistic);
  return data.data;
}

export async function getAccountsStatistic({commit}, params) {
  const {data} = await StatisticService.getAccountsStatistic(params);
  commit(SET_ACCOUNT_STATISTIC, data.data.statistic);
  return data.data;
}

export async function getCaptchaSolvedStatistic({commit}, params) {
  const {data} = await StatisticService.getCaptchaSolvedStatistic(params);
  commit(SET_CAPTCHA_SOLVED_STATISTIC, data.data.statistic);
  return data.data;
}