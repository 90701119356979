export function formatDate(date) {
  if (!date) {
    return undefined;
  }

  return new Date(date).toLocaleString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });
}

export function isNull(value) {
  return value == undefined || value == null || Number.isNaN(value);
}

export async function wait(ms) {
  return new Promise(function (resolve) {
    setTimeout(resolve, ms);
  });
}