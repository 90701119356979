/* eslint-disable no-unused-vars */
import AccountService from '@/services/account.js';
import { SET_ACCOUNT_INFO, SET_ACCOUNTS, SET_PAGINATION } from './types';

export async function changePassword({commit}, params) {
  const {data} = await AccountService.changePassword(params);
  return data;
}

export async function getInfo({commit}) {
  const {data} = await AccountService.getInfo();
  commit(SET_ACCOUNT_INFO, data.data);
  return data;
}

export async function getAccounts({commit}, params) {
  const {data} = await AccountService.getAccounts(params);
  commit(SET_ACCOUNTS, data.data.accounts);
  commit(SET_PAGINATION, data.data.pagination);
  return data.data;
}