<template>
  <div>
    <h1 style="font-size: 1.5em"><font-awesome-icon class="mr-10" :icon="['fas', 'cube']" /> List of Packages</h1>
    <br>
    <h1 v-if="!packages || packages.length == 0">You don't have any packages yet. Go to <u>Pricing Calculator</u> in <router-link :to="{name: 'HomePage', hash: '#pricing-caculator'}">HomePage</router-link> to buy</h1>
    <h1 v-if="packages && packages.length > 0">Do you want to buy more packages? Go to <u>Pricing Calculator</u> in <router-link :to="{name: 'HomePage', hash: '#pricing-caculator'}">HomePage</router-link> to buy</h1>
    <br v-if="packages && packages.length > 0">
    <a-row v-if="packages && packages.length > 0" :gutter="[48,48]">
      <a-col 
        v-for="(pkg, index) in packages" 
        :key="index" 
        :span="isMobile ? 24 : 8"
        >
        <a-card :loading="loading.loadPackages" hoverable :class="pkg.is_expired ? 'invalid-pkg' : 'valid-pkg'">
          <template slot="actions">
            <span v-if="!pkg.is_expired">
              <a-tooltip title="Change API-Key">
                <a-button :loading="loading.btns[`change_api_key_${pkg._id}`]" @click="handleChangeAPIKey(pkg._id)" class="card-btn" icon="api" style="color: blue"></a-button>
              </a-tooltip>
            </span>
            <span>
              <a-tooltip title="Renew">
                <a-button @click="openConfirmRenewPackage(index)" class="card-btn" icon="retweet" style="color: #14e02f"></a-button>
              </a-tooltip>
            </span>
            <a-tooltip title="Delete">
              <a-button :loading="loading.btns[`del_package_${pkg._id}`]" @click="handleDeletePackage(pkg._id)" class="card-btn" icon="delete" style="color: red"></a-button>
            </a-tooltip>
          </template>
          <a-card-meta>
            <h4 slot="title">
              <a-avatar :src="pkg.type == 'funcaptcha' ? `/images/funcaptcha.png` : '/images/itt.png'" style="margin-right: 5px"></a-avatar>
              {{pkg.is_expired ? `${capitalizeFirstLetter(pkg.type)} - Expired` : capitalizeFirstLetter(pkg.type)}} <small v-if="pkg.unlimited"><b>(Unlimited)</b></small>
            </h4>
            <div slot="description">
              <a-descriptions
                bordered
                layout="vertical"
                size="small"
              >
                <a-descriptions-item v-if="pkg.unlimited == false" label="Balance" :span="3">
                  <b :style="{color: pkg.balance <= 0 ? 'red' : 'blue'}">{{pkg.balance}}</b>/{{pkg.quantity}}
                </a-descriptions-item>
                <a-descriptions-item v-if="pkg.unlimited == true" label="Threads" :span="3">
                  <b :style="{color: 'blue'}">{{pkg.threads}}</b>
                </a-descriptions-item>
                <a-descriptions-item label="Period">
                  <b>{{pkg.period}}</b> days
                </a-descriptions-item>
                <a-descriptions-item label="Expired in">
                  {{ new Date(pkg.expired).toLocaleString() }}
                </a-descriptions-item>
              </a-descriptions>
              <br>
              <a-input-password v-model="pkg.api_key" :readOnly="true">
                <span slot="addonBefore">API-Key</span>
                <a-button @click="copyValue(pkg.api_key)" style="height: 25px; padding: 0; border: none" slot="addonAfter" type="secondary"><a-icon type="copy" /></a-button>
              </a-input-password>
            </div>
          </a-card-meta>
        </a-card>
      </a-col>
    </a-row>
    <a-modal v-model="visible.confirmRenewPackage" :destroyOnClose="true" title="Confirm Renew Package!" :footer="null">
      <confirm-purchase 
        v-if="renewPackage" 
        invoiceType="renew"
        :type="renewPackage.type" 
        :period="renewPackage.period" 
        :quantity="renewPackage.unlimited ? undefined : renewPackage.quantity" 
        :threads="renewPackage.unlimited ? renewPackage.threads : undefined"
        :price="priceRange ? priceRange.price : 0.8" 
        :package_id="renewPackage._id"
        :amount="costs" 
        @close="visible.confirmRenewPackage = false"
        @purchased="loadPackages"
      ></confirm-purchase>
    </a-modal>
  </div>
</template>

<style scoped>
.valid-pkg {
  background: #f6ffed;
  border-color: #b7eb8f;
}

.invalid-pkg {
  background: #fff1f0;
  border-color: #ffa39e;
}

.card-btn {
  height: 22px;
  background-color: transparent;
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
}
</style>

<script>
import ConfirmPurchase from '../ConfirmPurchase.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    ConfirmPurchase
  },
  data() {
    return {
      loading: {
        loadPackages: false,
        btns: {}
      },
      visible: {
        confirmRenewPackage: false
      },
      renewPackageIndex: -1
    }
  },
  async mounted() {
    this.getPrices();
    await this.loadPackages();
  },
  computed: {
    ...mapGetters('prices', {
      prices: "getPrices"
    }),

    ...mapGetters('packages', {
      packages: 'getPackages'
    }),

    isMobile() {
      return innerWidth <= 768;
    },

    renewPackage() {
      if (!this.packages || !(this.packages instanceof Array) || this.packages.length == 0) {
        return undefined;
      }

      return this.packages[this.renewPackageIndex];
    },

    priceRange() {
      if (!this.renewPackage) {
        return undefined;
      }

      if (!this.prices || !this.prices[this.renewPackage.type]) {
        return undefined;
      }

      const selectedPrice =  this.prices[this.renewPackage.type].periods[this.renewPackage.period];

      if (!selectedPrice) {
        return undefined;
      }

      return selectedPrice.find(range => range.min <= this.renewPackage.quantity && range.max >= this.renewPackage.quantity);
    },

    costs() {
      if (!this.priceRange) {
        return 0;
      }

      return this.renewPackage.quantity * (this.priceRange.price / 1000);
    }
  },
  methods: {
    ...mapActions('prices', {
      getPrices: "getPrices"
    }),

    ...mapActions('packages', {
      getUserPackages: 'getUserPackages',
      changeAPIKey: "changeAPIKey",
      deletePackage: "deletePackage"
    }),

    async loadPackages() {
      this.loading.loadPackages = true;
      await this.getUserPackages();
      this.loading.loadPackages = false;
    },

    capitalizeFirstLetter(string) {
      return string == 'funcaptcha' ? 'Funcaptcha' : 'Image To Text';
    },

    async copyValue(value) {
      await navigator.clipboard.writeText(value);
      this.$message.success("Copied");
    },

    async handleChangeAPIKey(packageId) {
      this.$confirm({
        title: 'Are you sure you want to change the API-Key for this package?',
        content: 'This action will disable the current API-Key!',
        onOk: async () => {
          this.$set(this.loading.btns, `change_api_key_${packageId}`, true);
          try {
            await this.changeAPIKey({package_id: packageId});
            this.$notification.success({message: `Package API-Key changed!`});
          }
          catch(error) {
            const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

            this.$notification.error({message});

            if (message.includes('Unauthorized')) {
              this.$router.push({name: 'LoginPage'});
            }
          }
          this.$set(this.loading.btns, `change_api_key_${packageId}`, false);
        }
      });
    },

    async handleDeletePackage(packageId) {
      this.$confirm({
        title: 'Are you sure you want to DELETE this package?',
        content: 'This action will permanently delete the package and you will not be able to recover it in the future!',
        onOk: async () => {
          this.$set(this.loading.btns, `del_package_${packageId}`, true);
          try {
            await this.deletePackage({package_id: packageId});
            this.$notification.success({message: `Package deleted!`});
          }
          catch(error) {
            const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

            this.$notification.error({message});

            if (message.includes('Unauthorized')) {
              this.$router.push({name: 'LoginPage'});
            }
          }
          this.$set(this.loading.btns, `del_package_${packageId}`, false);
        }
      });
    },

    openConfirmRenewPackage(pkgIndex) {
      this.renewPackageIndex = pkgIndex;
      this.visible.confirmRenewPackage = true;
    }
  },
}
</script>