export function getRole(state) {
  return state.role;
}

export function getBalance(state) {
  return state.balance;
}

export function getAccounts(state) {
  return state.accounts;
}

export function getPagination(state) {
  return state.pagination;
}