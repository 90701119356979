var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{staticClass:"root-card",attrs:{"title":"Register"}},[_c('a-form',{staticClass:"register-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleRegister}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          { rules: [
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Please enter a valid email address!' }
          ] },
        ]),expression:"[\n          'email',\n          { rules: [\n            { required: true, message: 'Please input your email!' },\n            { type: 'email', message: 'Please enter a valid email address!' }\n          ] },\n        ]"}],attrs:{"placeholder":"Email"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'verifycode',
          { rules: [
            { required: true, message: 'Please input email verify code!' },
            { pattern: /^\d+$/, message: 'Verification code must be an integer' },
            { len: 6, message: 'Verification code must be a 6-digit integer' }
          ] },
        ]),expression:"[\n          'verifycode',\n          { rules: [\n            { required: true, message: 'Please input email verify code!' },\n            { pattern: /^\\d+$/, message: 'Verification code must be an integer' },\n            { len: 6, message: 'Verification code must be a 6-digit integer' }\n          ] },\n        ]"}],attrs:{"placeholder":"Verify code","type":"number"},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('a-button',{staticClass:"register-form-button",staticStyle:{"height":"25px"},attrs:{"disabled":_vm.disabled.sendVerificationCode,"loading":_vm.loading.sendVerificationCode},on:{"click":_vm.handleSendVerificationCode}},[_vm._v("Send")])]},proxy:true}])},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"key"},slot:"prefix"})],1)],1),(_vm.disabled.sendVerificationCode)?_c('a-form-item',[_c('a-alert',{attrs:{"message":"If you do not receive the verification code sent to your email, you can try resending it after 60s!","type":"info","show-icon":""}})],1):_vm._e(),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          { rules: [
            { required: true, message: 'Please input your Password!' },
            { pattern: /^[a-zA-Z0-9@]+$/, message: 'Password must be a letter, number or @ character' },
            { min: 5, max: 25, message: 'Password length must be from 5 to 25 characters' }
          ] },
        ]),expression:"[\n          'password',\n          { rules: [\n            { required: true, message: 'Please input your Password!' },\n            { pattern: /^[a-zA-Z0-9@]+$/, message: 'Password must be a letter, number or @ character' },\n            { min: 5, max: 25, message: 'Password length must be from 5 to 25 characters' }\n          ] },\n        ]"}],attrs:{"placeholder":"Password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'confirmpassword',
          { rules: [{ required: true, message: 'Please input your confirm Password!' }, { validator: _vm.comparePassword }] },
        ]),expression:"[\n          'confirmpassword',\n          { rules: [{ required: true, message: 'Please input your confirm Password!' }, { validator: comparePassword }] },\n        ]"}],attrs:{"placeholder":"Confirm Password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('google-recaptcha-v2',{ref:"grecaptcha-component"}),_c('router-link',{attrs:{"to":{name: 'LoginPage'}}},[_c('a-icon',{attrs:{"type":"caret-left"}}),_vm._v(" Back to Log in ")],1),_c('a-form-item',[_c('a-button',{staticClass:"register-form-button",attrs:{"type":"primary","loading":_vm.loading.createAccount,"html-type":"submit"}},[_c('a-icon',{attrs:{"type":"user-add"}}),_vm._v(" Sign up ")],1),_c('p',{staticStyle:{"line-height":"25px"}},[_vm._v(" By creating an account, I agree to the "),_c('router-link',{attrs:{"to":{name: 'TermPage'},"tag":"a","target":"_blank"}},[_vm._v(" Terms & Conditions ")]),_vm._v(" and "),_c('router-link',{attrs:{"to":{name: 'RefundPolicyPage'},"tag":"a","target":"_blank"}},[_vm._v(" Refund Policy ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }