import VueRouter from "vue-router";
import Vue from "vue";

/**
 * Components
 */
import NotFound from '@/components/errors/NotFound.vue';

import LoginPage from '@/components/auth/LoginPage.vue';
import HomePage from "./components/pages/HomePage.vue";
import RegisterPage from "@/components/pages/accounts/RegisterPage.vue";
import TermPage from "@/components/pages/legal/TermPage.vue";
import RefundPolicyPage from "@/components/pages/legal/RefundPolicyPage.vue";
import PrivacyPolicyPage from "@/components/pages/legal/PrivacyPolicyPage.vue";
import ForgotPage from "@/components/pages/accounts/ForgotPage.vue";

import FreeTrialPage from "@/components/pages/FreeTrialPage.vue";

import AdminArea from "@/components/pages/admin/AdminArea.vue";
import AdminProfilePage from "@/components/pages/admin/ProfilePage.vue";
import AdminTransactionPage from "@/components/pages/admin/TransactionPage.vue";
import AdminAccountPage from "@/components/pages/admin/AccountPage.vue";
import AdminPackagePage from "@/components/pages/admin/PackagePage.vue";
import AdminStatisticPage from "@/components/pages/admin/StatisticPage.vue";
import AdminPromotionPage from "@/components/pages/admin/PromotionPage.vue";
import DevtoolsPage from "@/components/pages/admin/devtools/DevtoolsPage.vue";
import BdaDecryptor from "@/components/pages/admin/devtools/BdaDecryptor.vue";
import JsonCompare from '@/components/pages/admin/devtools/JsonCompare.vue';

import MemberArea from "@/components/pages/member/MemberArea.vue";
import MemberProfilePage from "@/components/pages/member/ProfilePage.vue";
import MemberDepositPage from "@/components/pages/member/DepositPage.vue";
import MemberTransactionPage from "@/components/pages/member/TransactionPage.vue";
import MemberPackagePage from "@/components/pages/member/PackagePage.vue";
import MemberApiPage from "@/components/pages/member/ApiPage.vue";
import MemberTrackingPage from "@/components/pages/member/TrackingPage.vue";
import MemberAffiliatePage from "@/components/pages/member/AffiliatePage.vue";

/**
 * Routes
 */

const routes = [
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/register',
    name: 'RegisterPage',
    component: RegisterPage
  },
  {
    path: '/forgot',
    name: 'ForgotPage',
    component: ForgotPage
  },
  {
    path: '/terms',
    name: 'TermPage',
    component: TermPage
  },
  {
    path: '/refund-policy',
    name: 'RefundPolicyPage',
    component: RefundPolicyPage
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicyPage',
    component: PrivacyPolicyPage
  },
  {
    path: '/trial',
    name: 'FreeTrialPage',
    component: FreeTrialPage
  },
  {
    path: '/member',
    component: MemberArea,
    name: 'MemberArea',
    children: [
      {
        path: '/',
        component: MemberProfilePage,
        name: 'MemberProfilePage',
      },
      {
        path: 'deposit',
        component: MemberDepositPage,
        name: 'MemberDepositPage',
      },
      {
        path: 'transactions',
        component: MemberTransactionPage,
        name: 'MemberTransactionPage'
      },
      {
        path: 'packages',
        component: MemberPackagePage,
        name: 'MemberPackagePage'
      },
      {
        path: 'api',
        component: MemberApiPage,
        name: 'MemberApiPage'
      },
      {
        path: 'tracking',
        component: MemberTrackingPage,
        name: 'MemberTrackingPage'
      },
      {
        path: 'affiliate',
        component: MemberAffiliatePage,
        name: 'MemberAffiliatePage'
      }
    ]
  },
  {
    path: '/admin',
    component: AdminArea,
    name: 'AdminArea',
    children: [
      {
        path: '/',
        component: AdminProfilePage,
        name: 'AdminProfilePage',
      },
      {
        path: 'transactions',
        component: AdminTransactionPage,
        name: 'AdminTransactionPage'
      },
      {
        path: 'accounts',
        component: AdminAccountPage,
        name: 'AdminAccountPage'
      },
      {
        path: 'packages',
        component: AdminPackagePage,
        name: 'AdminPackagePage'
      },
      {
        path: 'statistic',
        component: AdminStatisticPage,
        name: 'AdminStatisticPage'
      },
      {
        path: 'promotion',
        component: AdminPromotionPage,
        name: 'AdminPromotionPage'
      },
      {
        path: 'devtools',
        component: DevtoolsPage,
        name: 'DevtoolsPage',
        children: [
          {
            path: 'bda-decryptor',
            component: BdaDecryptor,
            name: 'BdaDecryptor',
          },
          {
            path: 'json-compare',
            component: JsonCompare,
            name: 'JsonCompare',
          },
        ]
      }
    ]
  },
];

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    } else {
      return { x: 0, y: 0 }
    }
  }
});

export default router;