<template>
  <a-form-model 
    ref="form" 
    :model="form" 
    :rules="rules" 
    :label-col="labelCol" 
    :wrapper-col="wrapperCol"
  >
    <a-form-model-item label="Code" prop="code">
      <a-input v-model.trim="form.code" />
    </a-form-model-item>

    <a-form-model-item label="Description" prop="description">
      <a-input :auto-size="{ minRows: 2, maxRows: 10 }" v-model.trim="form.description" type="textarea" />
    </a-form-model-item>

    <a-form-model-item label="Discount type" prop="discount_type">
      <a-radio-group v-model="form.discount_type">
        <a-radio value="fixed">
          Fixed ($)
        </a-radio>
        <a-radio value="percentage">
          Percentage (%)
        </a-radio>
      </a-radio-group>
    </a-form-model-item>

    <a-form-model-item has-feedback label="Discount value" prop="discount_value">
      <a-input v-model.number="form.discount_value" />
    </a-form-model-item>

    <a-form-model-item has-feedback label="Quantity" prop="quantity">
      <a-input v-model.number="form.quantity" />
    </a-form-model-item>

    <a-form-model-item has-feedback label="Use time" prop="use_time">
      <a-range-picker v-model="form.use_time" />
    </a-form-model-item>

    <a-card title="Conditions" class="custom-card">
      <a-form-model-item label="Accounts" prop="conditionAccount">
        <a-radio-group v-model="accountsConditions_option">
          <a-radio value="*">All</a-radio>
          <a-radio value="custom">Custom</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="List accounts" v-if="accountsConditions_option === 'custom'">
        <a-input :auto-size="{ minRows: 2, maxRows: 10 }" v-model.trim="form.conditions.accounts" type="textarea"
          placeholder="Enter accountId, one per line" />
      </a-form-model-item>

      <a-form-model-item label="Transaction" prop="conditionTransaction">
        <a-input-group compact style="display: inline-block; width: 100%;">
          <a-input v-model.number="form.conditions.transaction.min" style=" width: 45%; text-align: center"
            placeholder="Min" />
          <a-input style=" width: 30px; border-left: 0; pointer-events: none; background: #fff" placeholder="-"
            disabled />
          <a-input v-model.number="form.conditions.transaction.max"
            style="width: 45%; text-align: center; border-left: 0" placeholder="Max" />
        </a-input-group>
      </a-form-model-item>
    </a-card>

    <a-form-model-item style="margin-top: 20px; margin-bottom: 0px;" :wrapper-col="{ span: 6, offset: 18 }">
      <a-button style="margin-right: 16px;" @click="onCancel">
        Cancel
      </a-button>
      <a-button type="primary" @click="submitForm">
        {{ record ? 'Update' : 'Create' }}
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>

<style>
.custom-card .ant-card-head{
  background: #fafafa;
}
</style>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
  props: {
    record: {
      type: Object,
      required: false
    }
  },

  data() {
    let checkNumber = (rule, value, callback) => {
      if (!value && value !== 0) {
        callback(new Error('Please input the field'));
      }
      else if (!Number.isFinite(value)) {
        callback(new Error('Please input digits'));
      } else {
        callback();
      }
    }

    let checkConditionTransaction = (rule, value, callback) => {
      const min = this.form.conditions.transaction.min;
      const max = this.form.conditions.transaction.max;

      if((!min && min !== 0) || (!max && max !== 0)) {
        callback(new Error('Please input all conditions'));
      }
      else if(!Number.isFinite(min) || !Number.isFinite(max)) {
        callback(new Error('Please input digits'));
      }
      else if(!(min <= max)) {
        callback(new Error('Min must be less than or equal to Max'));
      }

      callback();
    }
    return {
      loading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        code: '',
        description: '',
        conditions: {
          accounts: undefined,
          transaction: {
            min: '',
            max: ''
          }
        },
        discount_type: '',
        discount_value: '',
        quantity: '',
        use_time: undefined
      },
      accountsConditions_option: '',
      rules: {
        code: [
          {required: true, message: 'Please input Discount code', trigger: 'blur'}
        ],
        use_time: [
          {required: true, message: 'Please input shelf life', trigger: 'change'}
        ],
        discount_type: [
          {required: true, message: 'Please select Discount type', trigger: 'change'}
        ],
        discount_value: [
          {required: true, validator: checkNumber, trigger: 'blur'}
        ],
        quantity: [
          {required: true, validator: checkNumber, trigger: 'change'}
        ],
        conditionTransaction: [
          {required: true, validator: checkConditionTransaction, trigger: 'change'}
        ]
      }
    }
  },

  mounted() {
    if(this.record) {
      const editableData = { ...this.record };

      const startedTime = new Date(editableData.started_at);
      const expiredTime = new Date(editableData.expired_at);
      editableData.use_time = [moment(startedTime, 'YYYY-MM-DD'), moment(expiredTime, 'YYYY-MM-DD')];

      if(editableData.conditions.accounts !== '*') {
        editableData.conditions.accounts = this.getAccountsFromRecord(editableData);

        this.accountsConditions_option = 'custom';
      }
      else {
        this.accountsConditions_option = '*'
      }
      
      this.form = { ...editableData };
    }
  },

  methods: {
    ...mapActions('promotion', {
      updateDiscountCode: 'updateDiscountCode',
      createDiscountCode: 'createDiscountCode'
    }),
    onCancel() {
      this.$emit('close');
    },

    getAccountsFromRecord(record) {
      const accounts = record.conditions.accounts;
      return accounts.join('\n');
    },

    getDataFromCustomTextArea() {
      const text = this.form.conditions.accounts;

      if(text.length == 0) {
        return [];
      }

      return text.split('\n').map(line => line.trim());
    },

    optimizedUseTime(useTimeData) {
      const startDate = useTimeData[0];
      const endDate = useTimeData[1];

      return [
        startDate.startOf('day').toISOString(),
        endDate.endOf('day').toISOString()
      ].join();
    },

    async handleSubmitData() {
      try {
        this.loading = true;

        const formData = {
          code: this.form.code,
          description: this.form.description,
          conditions: {
            transaction: {
              min: this.form.conditions.transaction.min,
              max: this.form.conditions.transaction.max,
            }
          },
          discount_type: this.form.discount_type,
          discount_value: this.form.discount_value,
          quantity: this.form.quantity,
          use_time: this.optimizedUseTime(this.form.use_time),
        }

        if(this.accountsConditions_option === '*') {
          formData.conditions.accounts = '*';
        }
        else {
          formData.conditions.accounts = this.getDataFromCustomTextArea();
        }

        if(this.record) {
          await this.updateDiscountCode({
            id: this.record.id, 
            params: formData
          });
        }
        else {
          await this.createDiscountCode(formData);
        }

        this.$notification.success({message: `Update discount code success!`});

        this.loading = false;
        this.onCancel();
      } catch (error) {
        this.$notification.error({message: error?.response?.data?.error?.message || error?.response?.data || error.message});
        this.loading = false;
      }
    },

    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.handleSubmitData();
        } else {
          return false;
        }
      });
    }
  }
}
</script>