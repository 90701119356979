<template>
  <a-layout-footer style="text-align: center; padding: 0px 50px 25px 50px; margin-top: 30px">
    <div class="navs">
      <router-link :to="{name: 'TermPage'}">
        Terms & Conditions
      </router-link>
      <span>|</span> 
      <router-link :to="{name: 'PrivacyPolicyPage'}">
        Privacy Policy
      </router-link>
      <span>|</span> 
      <router-link :to="{name: 'RefundPolicyPage'}">
        Refund Policy
      </router-link>
    </div>
    Copyright © 2024. All Rights Reserved
  </a-layout-footer>
</template>

<style scoped>
.navs {
  margin-bottom: 5px;
  text-align: center;
}

.navs a {
  margin: 0 10px;
}
</style>

<script>

export default {
  
}
</script>
