<template>
  <div>
    <a-descriptions title="Admin Info" layout="vertical" bordered>
      <a-descriptions-item label="Email">
        <b>{{ email }}</b>
      </a-descriptions-item>
      <a-descriptions-item label="Role">
        <b>{{ role }}</b>
      </a-descriptions-item>
      <a-descriptions-item label="Balance">
        <span style="color: green">$<b>{{ parseFloat(balance.toFixed(5)) || 0 }}</b></span>
      </a-descriptions-item>
    </a-descriptions>
    <br>
    <div class="ant-descriptions-title">Change Password</div>
    <a-form class="submit-form" :form="form" @submit="handleSubmit">
      <a-form-item>
        <a-input-password
          v-decorator="[
            'currentpassword',
            { rules: [
              { required: true, message: 'Please input your current Password!' },
              { pattern: /^[a-zA-Z0-9@]+$/, message: 'Current password must be a letter, number or @ character' },
              { min: 5, max: 25, message: 'Current password length must be from 5 to 25 characters' }
            ] },
          ]"
          placeholder="Current Password"
        >
          <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
        </a-input-password>
      </a-form-item>
      <a-form-item>
        <a-input-password
          v-decorator="[
            'newpassword',
            { rules: [
              { required: true, message: 'Please input your new Password!' },
              { pattern: /^[a-zA-Z0-9@]+$/, message: 'Password must be a letter, number or @ character' },
              { min: 5, max: 25, message: 'Password length must be from 5 to 25 characters' }
            ] },
          ]"
          placeholder="Password"
        >
          <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
        </a-input-password>
      </a-form-item>
      <a-form-item>
        <a-input-password
          v-decorator="[
            'confirmpassword',
            { rules: [{ required: true, message: 'Please input your confirm Password!' }, { validator: comparePassword }] },
          ]"
          placeholder="Confirm Password"
        >
          <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
        </a-input-password>
      </a-form-item>
      <google-recaptcha-v2 ref="grecaptcha-component"></google-recaptcha-v2>
      <a-form-item>
        <a-button type="primary" html-type="submit" class="submit-form-button">
          <a-icon type="save" /> Submit
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<style>
.submit-form {
  width: 300px;
  margin: 0 auto;
}
.submit-form-forgot {
  float: right;
}
.submit-form-button {
  width: 100%;
}

.submit-form .ant-form-item {
  margin-bottom: 10px;
}

@media screen and (max-width: 480px) {
  .submit-form {
    width: 100%;
  }

  .submit-form .ant-row {
    width: 100%;
  }
}
</style>

<script>
import GoogleRecaptchaV2 from '@/components/utils/GoogleRecaptchaV2.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    GoogleRecaptchaV2
  },
  data() {
    return {
      loading: {
        handleSubmit: false,
      }
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'change-password' });
  },
  mounted() {
    this.getInfo();
  },
  computed: {
    ...mapGetters("auth", {
      email: "getEmail",
    }),

    ...mapGetters('account', {
      balance: "getBalance",
      role: "getRole"
    }),

    grecaptchaComponent() {
      return this.$refs['grecaptcha-component'];
    }
  },
  methods: {
    ...mapActions('account', {
      changePassword: 'changePassword',
      getInfo: "getInfo"
    }),

    comparePassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue('newpassword')) {
        callback('Confirm password does not match password');
      } else {
        callback();
      }
    },

    handleSubmit(e) {
      e.preventDefault();

      if (!this.grecaptchaComponent.rendered) {
        this.grecaptchaComponent.render();
        return;
      }

      this.form.validateFields(async (err, values) => {
        if (err) {
          return;
        }

        if (!this.grecaptchaComponent.getResponse()) {
          this.$notification.error({message: 'Please verify the captcha'});
          return;
        }

        this.loading.handleSubmit = true;
        try {
          const {message} = await this.changePassword({...values, ...{
            recaptcha: this.grecaptchaComponent.getResponse()
          }});
          this.$notification.success({message});
          this.grecaptchaComponent.reset();
        }
        catch(error) {
          const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

          this.$notification.error({message});

          if (message.includes('Unauthorized')) {
            this.$router.push({name: 'LoginPage'});
          }
          
          this.grecaptchaComponent.reset();
        }
        this.loading.handleSubmit = false;
      });
    },
  },
}
</script>