var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{staticClass:"root-card",attrs:{"title":"Login"}},[_c('a-form',{staticClass:"login-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleLogin}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          { rules: [
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Please enter a valid email address!' }
          ] },
        ]),expression:"[\n          'email',\n          { rules: [\n            { required: true, message: 'Please input your email!' },\n            { type: 'email', message: 'Please enter a valid email address!' }\n          ] },\n        ]"}],attrs:{"placeholder":"Email"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          { rules: [
            { required: true, message: 'Please input your Password!' },
            { pattern: /^[a-zA-Z0-9@]+$/, message: 'Password must be a letter, number or @ character' },
            { min: 5, max: 25, message: 'Password length must be from 5 to 25 characters' }
          ] },
        ]),expression:"[\n          'password',\n          { rules: [\n            { required: true, message: 'Please input your Password!' },\n            { pattern: /^[a-zA-Z0-9@]+$/, message: 'Password must be a letter, number or @ character' },\n            { min: 5, max: 25, message: 'Password length must be from 5 to 25 characters' }\n          ] },\n        ]"}],attrs:{"placeholder":"Password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'remember',
          {
            valuePropName: 'checked',
            initialValue: true,
          },
        ]),expression:"[\n          'remember',\n          {\n            valuePropName: 'checked',\n            initialValue: true,\n          },\n        ]"}]},[_vm._v(" Remember ")]),_c('router-link',{staticClass:"login-form-forgot",attrs:{"to":{name: 'ForgotPage'}}},[_vm._v(" Forgot password ")]),_c('google-recaptcha-v2',{ref:"grecaptcha-component"}),_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit","loading":_vm.loginLoading}},[_c('a-icon',{attrs:{"type":"login"}}),_vm._v(" Log in ")],1),_vm._v(" Don't have an account? "),_c('router-link',{attrs:{"to":{name: 'RegisterPage'}}},[_vm._v(" Sign up ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }