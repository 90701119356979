<template>
  <div>
    <h1 style="font-size: 1.5em"><a-icon type="monitor" /> Realtime Tracking <small>(5 minutes recent)</small></h1>
    <br>
    <a-row :gutter="[24,8]">
      <a-col :span="isMobile ? 12 : 6">
        <a-statistic title="Success" :value="analytic.success" style="text-align: center" :value-style="{ color: '#3f8600' }">
          <template #suffix>
            <a-icon type="check-circle" />
          </template>
        </a-statistic>
      </a-col>
      <a-col :span="isMobile ? 12 : 6">
        <a-statistic title="Error" :value="analytic.error" style="text-align: center" :value-style="{ color: '#cf1322' }">
          <template #suffix>
            <a-icon type="close-circle" />
          </template>
        </a-statistic>
      </a-col>
      <a-col :span="isMobile ? 12 : 6">
        <a-statistic title="Processing" :value="analytic.processing" style="text-align: center" :value-style="{ color: '#89750b' }">
          <template #suffix>
            <a-icon type="clock-circle" />
          </template>
        </a-statistic>
      </a-col>
      <a-col :span="isMobile ? 12 : 6">
        <a-statistic title="Pending" :value="analytic.pending" style="text-align: center" :value-style="{ color: '#1f64d0' }">
          <template #suffix>
            <a-icon type="pause-circle" />
          </template>
        </a-statistic>
      </a-col>
    </a-row>
    <br>
    <a-table 
      :data-source="tableData" 
      :columns="columns" 
      :pagination="pagination" 
      :scroll="{x: 1000, y: tableHeight}"
    >
      <a-tag slot="id" slot-scope="id">{{id}}</a-tag>
      <span slot="type" slot-scope="type">
        <span v-if="type == 'image'"><a-avatar src="/images/itt.png" style="margin-right: 5px"></a-avatar>Image To Text</span>
        <span v-if="type == 'funcaptcha'"><a-avatar src="/images/funcaptcha.png" style="margin-right: 5px"></a-avatar>Funcaptcha</span>
      </span>
      <span slot="time" slot-scope="time">{{time}}<small>s</small></span>
      <span slot="status" slot-scope="status">
        <a-tag v-if="status == 'PENDING'" color="blue"><a-icon type="pause-circle" /> {{status}}</a-tag>
        <a-tag v-if="status == 'PROCESSING'" color="orange"><a-icon type="clock-circle" /> {{status}}</a-tag>
        <a-tag v-if="status == 'ERROR'" color="red"><a-icon type="close-circle" /> {{status}}</a-tag>
        <a-tag v-if="status == 'SUCCESS'" color="green"><a-icon type="check-circle" /> {{status}}</a-tag>
      </span>
      <span slot="createdTime" slot-scope="createdTime"><i>{{createdTime}}</i></span>
      <span slot="endTime" slot-scope="endTime"><i>{{endTime || 'null'}}</i></span>
      <span slot="refundable" slot-scope="refundable">
        <a-tag v-if="refundable == false" color="red"><a-icon type="stop" /> No</a-tag>
        <a-tag v-if="refundable == true" color="green"><a-icon type="check" /> Yes</a-tag>
      </span>
    </a-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

const columns = [
  {
    title: 'No.',
    dataIndex: 'no',
    key: 'no',
    width: 80,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: 'Task Id',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Start',
    dataIndex: 'createdTime',
    key: 'createdTime',
    scopedSlots: { customRender: 'createdTime' },
    width: 120
  },
  {
    title: 'End',
    dataIndex: 'endTime',
    key: 'endTime',
    scopedSlots: { customRender: 'endTime' },
    width: 120
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: 'Refundable',
    dataIndex: 'refundable',
    key: 'refundable',
    scopedSlots: { customRender: 'refundable' },
    width: 150
  }
];

export default {
  data() {
    return {
      columns,
      loading: {
      },
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
        showTotal: (total, range) => {
          return `${range[0]} - ${range[1]} of ${total}`;
        }
      },
    }
  },
  async mounted() {
    await this.loadTrackings();

    this.interval = setInterval(this.loadTrackings.bind(this), 3 * 1000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  computed: {
    ...mapGetters('tracking', {
      trackings: 'getTrackings',
      analytic: 'getAnalytic',
    }),

    isMobile() {
      return innerWidth <= 768;
    },

    tableHeight() {
      return Math.round(innerHeight / 2);
    },

    tableData() {
      if (!this.trackings) {
        return [];
      }

      if (this.trackings instanceof Array) {
        return this.trackings.map((tracking, index) => {
          tracking.key = tracking.id;
          tracking.no = index + 1;
          tracking.refundable = tracking.status == 'ERROR';
          tracking.time = tracking.endTime ? parseFloat(((tracking.endTime - tracking.createdTime)/1000).toFixed(2)) : parseInt((Date.now() - tracking.createdTime)/1000);
          tracking.createdTime = new Date(tracking.createdTime).toLocaleTimeString();
          tracking.endTime = tracking.endTime ? new Date(tracking.endTime).toLocaleTimeString() : null;

          return tracking;
        });
      }

      return [];
    }
  },
  methods: {
    ...mapActions('tracking', {
      getCaptchaTracking: 'getCaptchaTracking'
    }),

    async loadTrackings() {
      await this.getCaptchaTracking();
    },
  },
}
</script>