<template>
  <a-tabs v-model="activeKey" type="editable-card" @edit="onEdit">
    <a-tab-pane v-for="pane in panes" :key="pane.key" :tab="pane.title" :closable="pane.closable">
      <div :ref="`diffEditor${pane.key}`" style="height: 600px"></div>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
export default {
  data() {
    return {
      activeKey: undefined,
      panes: [],
      tabCounter: 0,
    };
  },
  mounted() {
    this.add();
  },
  methods: {
    onEdit(targetKey, action) {
      this[action](targetKey);
    },
    add() {
      this.tabCounter ++;
      this.panes.push({
        title: `Tab ${this.tabCounter}`, 
        content: 'Content of new Tab', 
        key: this.tabCounter
      });
      this.activeKey = this.tabCounter;
      setTimeout(() => {
        this.loadMonacoDiffEditor(this.tabCounter);
      }, 500);
    },
    remove(targetKey) {
      let activeKey = this.activeKey;
      let lastIndex;
      this.panes.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      const panes = this.panes.filter(pane => pane.key !== targetKey);
      if (panes.length && activeKey === targetKey) {
        if (lastIndex >= 0) {
          activeKey = panes[lastIndex].key;
        } else {
          activeKey = panes[0].key;
        }
      }
      this.panes = panes;
      this.activeKey = activeKey;
    },
    loadMonacoDiffEditor(key) {
      window.require.config({ paths: { vs: "https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.51.0/min/vs" } });
      window.require(["vs/editor/editor.main"], () => {
        // eslint-disable-next-line no-undef
        const editor = monaco.editor.createDiffEditor(this.$refs[`diffEditor${key}`][0], {
          theme: 'vs-dark',
          automaticLayout: true,
          originalEditable: true
        });

        editor.setModel({
          // eslint-disable-next-line no-undef
          original: monaco.editor.createModel('', 'json'),
          // eslint-disable-next-line no-undef
          modified: monaco.editor.createModel('', 'json')
        });
      });
    },
  },
};
</script>