<template>
  <a-card title="Register" class="root-card">
    <a-form :form="form" class="register-form" @submit="handleRegister">
      <a-form-item>
        <a-input
          v-decorator="[
            'email',
            { rules: [
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Please enter a valid email address!' }
            ] },
          ]"
          placeholder="Email"
        >
          <a-icon slot="prefix" type="mail" style="color: rgba(0,0,0,.25)" />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          v-decorator="[
            'verifycode',
            { rules: [
              { required: true, message: 'Please input email verify code!' },
              { pattern: /^\d+$/, message: 'Verification code must be an integer' },
              { len: 6, message: 'Verification code must be a 6-digit integer' }
            ] },
          ]"
          placeholder="Verify code"
          type="number"
        >
          <a-icon slot="prefix" type="key" style="color: rgba(0,0,0,.25)" />
          <template #suffix>
            <a-button @click="handleSendVerificationCode" :disabled="disabled.sendVerificationCode" :loading="loading.sendVerificationCode" style="height: 25px" class="register-form-button">Send</a-button>
          </template>
        </a-input>
      </a-form-item>
      <a-form-item v-if="disabled.sendVerificationCode">
        <a-alert message="If you do not receive the verification code sent to your email, you can try resending it after 60s!" type="info" show-icon />
      </a-form-item>
      <a-form-item>
        <a-input-password
          v-decorator="[
            'password',
            { rules: [
              { required: true, message: 'Please input your Password!' },
              { pattern: /^[a-zA-Z0-9@]+$/, message: 'Password must be a letter, number or @ character' },
              { min: 5, max: 25, message: 'Password length must be from 5 to 25 characters' }
            ] },
          ]"
          placeholder="Password"
        >
          <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
        </a-input-password>
      </a-form-item>
      <a-form-item>
        <a-input-password
          v-decorator="[
            'confirmpassword',
            { rules: [{ required: true, message: 'Please input your confirm Password!' }, { validator: comparePassword }] },
          ]"
          placeholder="Confirm Password"
        >
          <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
        </a-input-password>
      </a-form-item>
      <google-recaptcha-v2 ref="grecaptcha-component"></google-recaptcha-v2>
      <router-link :to="{name: 'LoginPage'}">
        <a-icon type="caret-left" /> Back to Log in
      </router-link>
      <a-form-item>
        <a-button type="primary" :loading="loading.createAccount" html-type="submit" class="register-form-button">
          <a-icon type="user-add" /> Sign up
        </a-button>
        <p style="line-height:25px">
          By creating an account, I agree to the
          <router-link :to="{name: 'TermPage'}" tag="a" target="_blank">
            Terms & Conditions
          </router-link>
          and
          <router-link :to="{name: 'RefundPolicyPage'}" tag="a" target="_blank">
            Refund Policy
          </router-link>
        </p>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import GoogleRecaptchaV2 from '@/components/utils/GoogleRecaptchaV2.vue';
import {mapActions} from 'vuex';

export default {
  components: {
    GoogleRecaptchaV2
  },
  data() {
    return {
      loading: {
        sendVerificationCode: false,
        createAccount: false
      },
      disabled: {
        sendVerificationCode: false,
      }
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'register' });
  },
  computed: {
    grecaptchaComponent() {
      return this.$refs['grecaptcha-component'];
    }
  },
  methods: {
    ...mapActions('register', {
      sendVerificationCode: 'sendVerificationCode',
      createAccount: 'createAccount'
    }),

    comparePassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue('password')) {
        callback('Confirm password does not match password');
      } else {
        callback();
      }
    },

    handleRegister(e) {
      e.preventDefault();

      if (!this.grecaptchaComponent.rendered) {
        this.grecaptchaComponent.render();
        return;
      }

      this.form.validateFields(async (err, values) => {
        if (err) {
          return;
        }

        if (!this.grecaptchaComponent.getResponse()) {
          this.$notification.error({message: 'Please verify the captcha'});
          return;
        }

        this.loading.createAccount = true;
        try {
          const {message} = await this.createAccount({...values, ...{
            recaptcha: this.grecaptchaComponent.getResponse()
          }});
          this.$notification.success({message});
          this.$router.push({name: 'LoginPage', params: { email: values.email, password: values.password }});
        }
        catch(error) {
          const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

          this.$notification.error({message});

          if (message.includes('Unauthorized')) {
            this.$router.push({name: 'LoginPage'});
          }

          this.grecaptchaComponent.reset();
        }
        this.loading.createAccount = false;
      });
    },

    async handleSendVerificationCode(e) {
      e.preventDefault();

      const isError = !!this.form.getFieldError('email');

      if (isError) {
        return;
      }

      const email = this.form.getFieldValue('email');

      if (!email) {
        this.$notification.error({message: 'Please input your Email!'});
        return;
      }

      this.loading.sendVerificationCode = true;
      try {
        const {message} = await this.sendVerificationCode({email});
        this.$notification.success({message});
        this.disabled.sendVerificationCode = true;

        setTimeout(() => {
          this.disabled.sendVerificationCode = false;
        }, 60 * 1000);
      }
      catch(error) {
        const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

        this.$notification.error({message});

        if (message.includes('Unauthorized')) {
          this.$router.push({name: 'LoginPage'});
        }
      }
      this.loading.sendVerificationCode = false;
    }
  },
};
</script>
<style>
.register-form {
  width: 300px;
  margin: 0 auto;
}
.register-form-forgot {
  float: right;
}
.register-form-button {
  width: 100%;
}

.register-form .ant-form-item {
  margin-bottom: 10px;
}

@media screen and (max-width: 480px) {
  .register-form {
    width: 100%;
  }

  .register-form .ant-row {
    width: 100%;
  }
}
</style>