/* eslint-disable no-unused-vars */
import RegisterService from '@/services/register';

export async function sendVerificationCode({commit}, params) {
  const {data} = await RegisterService.sendVerificationCode(params);
  return data;
}

export async function createAccount({commit}, params) {
  const {data} = await RegisterService.createAccount(params);
  return data;
}