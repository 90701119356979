var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-button',{staticClass:"bank-btn",staticStyle:{"background-color":"#c8fff9cc"},on:{"click":function($event){_vm.depositType = _vm.DepositTypes.ACB}}},[_c('img',{attrs:{"src":"/images/acb.svg"}})]),_c('br'),_c('br'),(_vm.depositType)?_c('a-form',{staticClass:"submit-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[(_vm.depositAmountUsd)?_c('a-form-item',[_c('a-alert',{attrs:{"message":`You will receive ~ $${_vm.depositAmountUsd}`,"type":"info","show-icon":""}})],1):_vm._e(),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'amount',
          { rules: [
            { required: true, message: 'Please enter amount!' },
            { message: `Amount must be at least ${_vm.currentDepositInfo.minpay} VND`, validator: (rule, value) => Number(value) >= _vm.currentDepositInfo.minpay }
          ] },
        ]),expression:"[\n          'amount',\n          { rules: [\n            { required: true, message: 'Please enter amount!' },\n            { message: `Amount must be at least ${currentDepositInfo.minpay} VND`, validator: (rule, value) => Number(value) >= currentDepositInfo.minpay }\n          ] },\n        ]"}],attrs:{"type":"number","addon-before":`[${_vm.depositType}]`,"addon-after":"VND","placeholder":"Amount"},on:{"change":_vm.caculateAmount}})],1),_c('a-form-item',[_c('a-alert',{attrs:{"message":"The exchange rate from US dollars to Vietnamese dong is 25,000!","type":"warning","show-icon":""}})],1),_c('a-form-item',[_c('a-button',{staticClass:"submit-form-button",attrs:{"type":"primary","html-type":"submit"}},[_c('a-icon',{attrs:{"type":"qrcode"}}),_vm._v(" Pay Invoice ")],1)],1),_c('a-modal',{attrs:{"destroyOnClose":true,"title":"Scan QR to pay"},on:{"ok":function($event){_vm.payInvoiceVisible = false}},model:{value:(_vm.payInvoiceVisible),callback:function ($$v) {_vm.payInvoiceVisible=$$v},expression:"payInvoiceVisible"}},[_c('viet-qr-image',{attrs:{"bank":_vm.depositType,"amount":_vm.currentDepositInfo.amount,"id":_vm.currentDepositInfo.id,"name":_vm.currentDepositInfo.name,"message":_vm.currentDepositInfo.message}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }