<template>
  <div>
    <h1 style="font-size: 1.5em"><a-icon type="tool" /> Devtools</h1>
    <a-tabs v-model="activeKey" @change="onChangeMenu">
      <a-tab-pane key="BdaDecryptor">
        <span slot="tab">BDA Decryptor</span>
        <bda-decryptor></bda-decryptor>
      </a-tab-pane>
      <a-tab-pane key="JsonCompare">
        <span slot="tab">Json Compare</span>
        <json-compare></json-compare>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import BdaDecryptor from './BdaDecryptor.vue';
import JsonCompare from './JsonCompare.vue';

export default {
  components: {
    BdaDecryptor,
    JsonCompare
  },
  data() {
    return {
      activeKey: undefined,
    }
  },
  mounted() {
    this.activeKey = this.$route.name != 'DevtoolsPage' ? this.$route.name : 'BdaDecryptor';
    this.$router.push({name: this.activeKey});
  },
  methods: {
    onChangeMenu(key) {
      this.$router.push({name: key});
    }
  }
}
</script>
