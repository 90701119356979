<template>
  <div>
    <div class="qr-code" ref="qrcode"></div>
  </div>
</template>

<style>
.qr-code img {
  width: 100%;
}
</style>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 128
    },
    height: {
      type: Number,
      default: 128
    },
    colorDark: {
      type: String,
      default: '#000000'
    },
    colorLight: {
      type: String,
      default: '#ffffff'
    },
    correctLevel: {
      type: Number,
      default: 2
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    new QRCode(this.$refs['qrcode'], {
      text: this.text,
      width: this.width,
      height: this.height,
      colorDark : this.colorDark,
      colorLight : this.colorLight,
      correctLevel : this.correctLevel
    });
  },
}
</script>