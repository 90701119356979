<template>
  <a-form-model 
    ref="form" 
    :model="form" 
    :rules="rules" 
    :label-col="labelCol" 
    :wrapper-col="wrapperCol"
  >
    <p><i>You will {{actionType}} period for <b>{{selectedLength}}</b> packages</i></p>
    <a-form-model-item has-feedback label="Update value" prop="period">
      <a-input v-model.number="form.period" />
    </a-form-model-item>

    <a-form-model-item style="margin-top: 20px; margin-bottom: 0px;" :wrapper-col="{ span: 10, offset: 16 }">
      <a-button style="margin-right: 16px;" @click="onCancel">
        Cancel
      </a-button>
      <a-button type="primary" @click="submitForm">
        Update
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>

<style>
.custom-card .ant-card-head{
  background: #fafafa;
}
</style>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    packageIds: {
      type: Array,
      required: true
    },   
    selectedLength: {
      type: Number,
      required: false
    },
    actionType: {
      type: String,
      required: true
    }
  },

  data() {
    let checkNumber = (rule, value, callback) => {
      if (!value && value !== 0) {
        callback(new Error('Please input the field'));
      }
      else if (!Number.isFinite(value)) {
        callback(new Error('Please input digits'));
      } else {
        callback();
      }
    }
    return {
      loading: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      form: {
        period: ''
      },
      rules: {
        period: [
          {required: true, validator: checkNumber, trigger: 'change'}
        ]
      }
    }
  },

  methods: {
    ...mapActions('packages', {
      updatePackagePeriods: 'updatePackagePeriods'
    }),
    onCancel() {
      this.$emit('close');
    },

    async handleSubmitData() {
      try {
        this.loading = true;

        await this.updatePackagePeriods({
          package_ids: this.packageIds, 
          period: (this.actionType == 'increase') ? (this.form.period) : (- this.form.period)
        });

        this.$notification.success({message: `Update package periods success!`});

        this.loading = false;
        this.onCancel();
      } catch (error) {
        this.$notification.error({message: error?.response?.data?.error?.message || error?.response?.data || error.message});
        this.loading = false;
      }
    },

    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.handleSubmitData();
        } else {
          return false;
        }
      });
    }
  }
}
</script>