import request from "./base";
const path = "/api/accounts";
const adminPath = "/api/admin/accounts";

export default {
  changePassword: function (params) {
    return request().post(path + "/change-password", params);
  },
  getInfo: function () {
    return request().get(path + "/info");
  },
  getAccounts: function (params) {
    return request().get(adminPath + "/", {params});
  }
};
