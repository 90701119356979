import request from "./base";
const path = "/trial";

export default {
  createImageTask: function (params) {
    return request().post(path + "/Recognition", params);
  },
  getTaskResult: function (taskId) {
    return request().get(path + "/getresult", {params: {taskId}});
  },
  createFuncaptchaTask: function (params) {
    return request().post(path + "/FunCaptchaTokenTask", params);
  },
};
