import {SET_ACCOUNT_INFO, SET_ACCOUNTS, SET_PAGINATION} from './types';

const state = {
  balance: 0,
  role: undefined,
  accounts: [],
  pagination: undefined
};

const mutations = {
  [SET_ACCOUNT_INFO](state, info) {
    state.balance = info.balance;
    state.role = info.role;
  },
  [SET_ACCOUNTS](state, accounts) {
    state.accounts = accounts;
  },
  [SET_PAGINATION](state, pagination) {
    state.pagination = pagination;
  }
};

import * as getters from "./getters";
import * as actions from "./actions";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};