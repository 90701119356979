<template>
  <a-card title="Recovery your password" class="root-card">
    <a-form :form="form" class="forgot-form" @submit="handleRecovery">
      <a-form-item>
        <a-input
          v-decorator="[
            'email',
            { rules: [
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Please enter a valid email address!' }
            ] },
          ]"
          placeholder="Email"
        >
          <a-icon slot="prefix" type="mail" style="color: rgba(0,0,0,.25)" />
        </a-input>
      </a-form-item>
      <a-form-item v-if="disabled.recovery">
        <a-alert message="If you do not receive the password recovery email, you can try resending it after 60s!" type="info" show-icon />
      </a-form-item>
      <google-recaptcha-v2 ref="grecaptcha-component"></google-recaptcha-v2>
      <router-link :to="{name: 'LoginPage'}">
        <a-icon type="caret-left" /> Back to Log in
      </router-link>
      <a-form-item>
        <a-button type="primary" :loading="loading.recovery" :disabled="disabled.recovery" html-type="submit" class="forgot-form-button">
          <a-icon type="play-circle" /> Send
        </a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import GoogleRecaptchaV2 from '@/components/utils/GoogleRecaptchaV2.vue';
import {mapActions} from 'vuex';

export default {
  components: {
    GoogleRecaptchaV2
  },
  data() {
    return {
      loading: {
        recovery: false,
      },
      disabled: {
        recovery: false,
      }
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'recovery' });
  },
  computed: {
    grecaptchaComponent() {
      return this.$refs['grecaptcha-component'];
    }
  },
  methods: {
    ...mapActions('recovery', {
      sendEmailRecoveryPassword: 'sendEmailRecoveryPassword',
    }),

    handleRecovery(e) {
      e.preventDefault();

      if (!this.grecaptchaComponent.rendered) {
        this.grecaptchaComponent.render();
        return;
      }

      this.form.validateFields(async (err, values) => {
        if (err) {
          return;
        }

        if (!this.grecaptchaComponent.getResponse()) {
          this.$notification.error({message: 'Please verify the captcha'});
          return;
        }

        this.loading.recovery = true;
        try {
          const {message} = await this.sendEmailRecoveryPassword({...values, ...{
            recaptcha: this.grecaptchaComponent.getResponse()
          }});
          this.$notification.success({message});

          this.disabled.recovery = true;

          setTimeout(() => {
            this.disabled.recovery = false;
          }, 60 * 1000);
        }
        catch(error) {
          const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

          this.$notification.error({message});

          if (message.includes('Unauthorized')) {
            this.$router.push({name: 'LoginPage'});
          }
          
          this.grecaptchaComponent.reset();
        }
        this.loading.recovery = false;
      });
    }
  },
};
</script>
<style>
.forgot-form {
  width: 300px;
  margin: 0 auto;
}
.forgot-form-forgot {
  float: right;
}
.forgot-form-button {
  width: 100%;
}

.forgot-form .ant-form-item {
  margin-bottom: 10px;
}

@media screen and (max-width: 480px) {
  .forgot-form {
    width: 100%;
  }

  .forgot-form .ant-row {
    width: 100%;
  }
}
</style>