<template>
  <a-menu mode="horizontal" class="menu" :selectedKeys="[selectedKey]">
    <a-menu-item key="Home">
      <router-link to="/">
        <logo-icon style="width: 30px; vertical-align:middle;" />
        MacroRC Captcha
      </router-link>
    </a-menu-item>
    <a-menu-item key="APIDocument">
      <a href="https://docx.mrccaptcha.com" target="_blank">
        <font-awesome-icon class="mr-10" :icon="['fas', 'code']" /> API Document
      </a>
    </a-menu-item>
    <a-menu-item key="FollowUs">
      <a href="https://t.me/mrccaptcha_channel" target="_blank">
        <a-icon type="sound" /> Follow Us
      </a>
    </a-menu-item>
    <a-menu-item key="ContactSupport">
      <a href="https://t.me/nguyenphuong_99" target="_blank">
        <font-awesome-icon class="mr-10" :icon="['fas', 'headset']" /> Contact Support
      </a>
    </a-menu-item>
    <a-menu-item v-if="!username" key="RegisterPage" class="menu-right">
      <router-link :to="{name: 'RegisterPage'}">
        <a-icon type="user-add" /> Join Us
      </router-link>
    </a-menu-item>
    <a-menu-item v-if="!username" key="LoginPage" class="menu-right">
      <router-link :to="{name: 'LoginPage'}">
        <a-icon type="user" /> Login
      </router-link>
    </a-menu-item>
    <a-menu-item key="User" v-if="username" class="menu-right">
      <router-link :to="{name: role == 'member' ? 'MemberProfilePage' : 'AdminProfilePage'}">
        <a-icon type="user" />{{username || 'Guest'}}
      </router-link>
    </a-menu-item>
  </a-menu>
</template>

<style scoped>
.menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 0 25px !important;
}

.menu-right {
  float: right;
  margin-left: auto;
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import LogoIcon from '@/assets/images/LogoIcon.vue';

export default {
  name: 'NavigationLayout',
  components: {
    LogoIcon
  },
  computed: {
    ...mapGetters("auth", {
      username: "getEmailUser",
      role: "getRole"
    }),

    selectedKey() {
      return this.$route.name;
    }
  },
  methods: {
    ...mapActions("auth", {
      removeSession: "removeSession"
    }),

    logout() {
      this.removeSession();
      this.$router.push({name: 'LoginPage'});
    }
  }
}
</script>